<template lang="pug">
    div(class="invoiceFooter")
        .row
          .col-sm-6.mt-1.invoiceFooterLeft
          div.col-sm-6.mt-2.invoiceFooterRight
            div.print-signature.mt-2.border.rounded.content-total(style="page-break-inside: avoid;" v-show="receiptFormProvider.nature==0")
                | Signature du client précédée de la mention "Lu et approuvé, bon pour accord" :
        .print-footer.mb-1(ref="printFooter")
            span(v-html="receiptFormProvider.footer")
    </template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tvaDetails: [],
      printFooterHeight: 0,
    };
  },
  computed: {
    ...mapGetters(["receiptFormProvider"]),
  },
};
</script>
