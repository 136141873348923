//TO COMPLETE FOR PURCHASE MODULE
import { ReceiptFormRequestDto, UserRequestDto } from "Api";
export function ReceiptFormProviderDefaultProperties(
  user: UserRequestDto,
  institution
): ReceiptFormRequestDto {
  return {
    id: undefined,
    affairId: null,
    affairName: null,
    affairAddress: null,
    affairAddressComplement: null,
    affairCity: null,
    affairZipCode: null,
    affairCountry: null,
    /** @format double */
    companyProviderId: undefined,
    companyProviderName: null,
    companyProviderAddress: null,
    companyProviderAddressComplement: null,
    companyProviderZipCode: null,
    companyProviderCity: null,
    companyProviderCountry: null,
    companyCustomerId: undefined,
    companyCustomerName: null,
    companyCustomerAddress: null,
    companyCustomerAddressComplement: null,
    companyCustomerZipCode: null,
    companyCustomerCity: null,
    companyCustomerCountry: null,
    companyCustomerPhoneNumber: null,
    companyCustomerMail: null,
    companyCustomerSiret: null,
    companyCustomerTva: null,
    storageLocationId: undefined,
    storageLocationLabel: null,
    storageLocationType: 0,
    storageLocationAddress: null,
    storageLocationAddressComplement: null,
    storageLocationZipCode: null,
    storageLocationCity: null,
    storageLocationPhoneNumber: null,
    storageLocationEmail: null,
    object: null,
    deliverToAffairAddress: false,

    /** @format date-time */
    documentDate: new Date().toISOString(),
    dateGenerated: new Date().toISOString(),
    receiptDate: new Date().toISOString(),

    /** @format date-time */
    comments: null,
    path: null,
    internalNote: null,

    /** @format int32 */
    userId: user.id,
    userLastName: user.lastName,
    userFirstName: user.firstName,
    userPhoneNumber: user.phoneNumber,
    userSecondaryPhoneNumber: null,
    userMail: user.email,
    status: 0,
    totalQuantityProduct: 0,

    /** @format int32 */
    institutionId: undefined,
    institutionName: null,
    institutionLegalForm: undefined,
    institutionAddress: null,
    institutionAddressComplement: null,
    institutionZipCode: null,
    institutionCity: null,
    institutionCountry: null,
    institutionPhoneNumber: null,
    institutionSecondaryPhoneNumber: null,
    institutionEmail: null,
    institutionWebSite: null,
    institutionRegistrationNumber: null,
    institutionIsSubjectTVA: undefined,
    institutionTvaNumber: null,
    institutionSiret: null,
    institutionRcsCity: null,

    /** @format double */
    institutionCapital: null,
    institutionApeCode: institution.apeCode,
    institutionGuaranteeType: institution.guaranteeType,
    institutionInsuranceName: institution.insuranceName,
    institutionInsuranceCoverage: institution.insuranceCoverage,
    institutionInsuranceAddress: institution.insuranceAddress,
    institutionInsuranceAddressComplement:
      institution.insuranceAddressComplement,
    institutionInsuranceZipCode: institution.insuranceZipCode,
    institutionInsuranceCity: institution.insuranceCity,
    institutionInsuranceCountry: institution.insuranceCountry,
    institutionLogo: institution.logo,
    institutionColor: institution.color,
    institutionSecondaryColor: institution.secondaryColor,
  };
}
