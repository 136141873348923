<template lang="pug">
.document-line.position-relative(ref="documentDetails" @mouseover="edit = true, mouseOn = true" @mouseleave="edit=false, mouseOn=false")
    input(class="w-100" :ref="'fakeInputReceiptFormProvider'+index" style="width: 0px !important; position: absolute; display:block")
    .product-line.builder(:class="line.type == 4 ? 'align-items-center' : '' ")
      span.feather-drag-document-detail.handle.cursor-move(class='material-icons') drag_indicator
      div.type-line.no-printting.material-icons-outlined.text-danger(v-if="!this.line.description && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-danger title="Description obligatoire" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-danger(v-else-if="!this.line.reference && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-danger title="Référence obligatoire" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-danger(v-else-if="productAlreadyExist(this.line) && !this.line.productId" v-b-tooltip.hover.v-danger title="Ce produit existe dans votre catalogue" style="font-size:14px") error
      div.type-line.no-printting.material-icons-outlined.text-warning.cursor-pointer(v-else-if="(this.line.productProviderId == null || this.line.productProviderId == 0) && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-warning title="Produit fournisseur inexistant dans votre base. Cliquez pour l'ajouter" style="font-size:14px" @click="openModal(false)") info
      div.type-line.no-printting.material-icons-outlined.text-warning.cursor-pointer(v-else-if="(this.line.productId == null || this.line.productId == 0) && ([7,8,9,10].includes(this.line.type))" v-b-tooltip.hover.v-warning title="Produit de vente inexistant sur le produit fournisseur" style="font-size:14px" @click="openModal(true)") info
      div.type-line.no-printting.material-icons-outlined.text-primary(v-else style="font-size:14px") done
      // b-button() Hover Me
      //div.type-line.no-printting {{orderFormProviderDetailTypeTranslate(line.type)}}
      ReceiptFormProviderDetailCommonLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in Object.keys(receiptFormProviderHeader)" :key="i"  :dataKey="key" v-if="receiptFormProviderHeader[key].display && (line.type == 7 || line.type == 8 || line.type == 9 || line.type == 10)" :class="receiptFormProviderHeader[key].class" :style="receiptFormProviderHeader[key].display ? receiptFormProviderHeader[key].style : ''" :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      ReceiptFormProviderDetailSectionLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 1 || line.type == 2" :line="line" :class="receiptFormProviderHeader[key].class" :style="receiptFormProviderHeader[key].style" :receiptFormProviderHeader="receiptFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      //- ReceiptFormProviderDetailSubTotalLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 5" :line="line" :class="receiptFormProviderHeader[key].class" :style="receiptFormProviderHeader[key].style" :receiptFormProviderHeader="receiptFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      ReceiptFormProviderDetailTextLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 6" :class="receiptFormProviderHeader[key].class" :style="receiptFormProviderHeader[key].style" :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :editFocus="editFocus" :edit="edit" :mouseOn="mouseOn" :index="index")
      ReceiptFormProviderDetailBreakPageLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 4"  :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      ReceiptFormProviderDetailBreakLine.m-0(@emitEditFocus="changeEditFocus" v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 3"  :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :edit="edit" :mouseOn="mouseOn" :editFocus="editFocus" :index="index")
      vs-dropdown.more-actions(vs-trigger-click v-if="!document.orderFormProviderId")
        span.handle.cursor-pointer(class='material-icons') expand_more
        vs-dropdown-menu
          vs-dropdown-item(v-if="line.productId" @click="showProductDetails(line)")
            | Afficher la fiche produit
          vs-divider(v-if="line.productId").p-0.m-0
          vs-dropdown-group(vs-collapse='' vs-label='Insérer au dessus' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:7, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:8, unit : 2, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:9, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:10, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index, review: true})")  Autre
          vs-dropdown-group(vs-collapse='' vs-label='Insérer en dessous' vs-icon='add')
            vs-dropdown-item(@click="newLine({type:7, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Fourniture
            vs-dropdown-item(@click="newLine({type:8, unit : 2, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Main d'oeuvre
            vs-dropdown-item(@click="newLine({type:9, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Matériel
            vs-dropdown-item(@click="newLine({type:10, unit : 1, referencielTvaId:line.referencielTvaId, indexArray: index+1, review: true})")  Autre
          vs-divider.p-0.m-0
          vs-dropdown-group(vs-collapse='' vs-label='Dupliquer' vs-icon='add')
            vs-dropdown-item(@click="duplicateLineReceiptFormProvider({ payload: {line: line, index: index}, position: 'before' })")
              | Au dessus
            vs-dropdown-item(@click="duplicateLineReceiptFormProvider({ payload: {line: line, index: index}, position: 'after' })")
              | En dessous
            vs-dropdown-item(@click="duplicateLineReceiptFormProvider({ payload: {line: line, index: index}, position: 'beginning' })")
              | Au début
            vs-dropdown-item(@click="duplicateLineReceiptFormProvider({ payload: {line: line, index: index}, position: 'end' })")
              | À la fin 
          vs-dropdown-item.text-danger(@click="deleteLine(line)" :disabled="line.isDisabled")
            | Supprimer
    .loading-overlay(v-show="line.isCreatingProduct && line.isCreatingProduct == true") Création du produit fournisseur et du produit de vente/stock en cours...
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-product-content(ref="sidebarProductContent" @closeSidebar="closeSidebar")
</template>

<script>
import ReceiptFormProviderDetailCommonLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailCommonLine.vue";
import ReceiptFormProviderDetailSectionLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailSectionLine.vue";
import ReceiptFormProviderDetailTextLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailTextLine.vue";
import ReceiptFormProviderDetailBreakPageLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailBreakPageLine.vue";
import ReceiptFormProviderDetailBreakLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailBreakLine.vue";
import ReceiptFormProviderDetailPriceLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailPriceLine.vue";
// import ReceiptFormProviderDetailSubTotalLine from "@/components/purchase/receipt-form-provider/builder/table/lines/ReceiptFormProviderDetailSubTotalLine.vue";
import { mapGetters, mapActions } from "vuex";
import { lineChartOptions } from "@/@core/components/statistics-cards/chartOptions";
import { orderFormProviderDetailTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import SidebarProductContent from "@/components/catalog/SidebarProductContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  data() {
    return {
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      isLoading: true,
      edit: false,
      mouseOn: false,
      editFocus: false,
    };
  },
  computed: {
    receiptFormProviderHeader: {
      get() {
        return this.$store.getters.getReceiptFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setReceiptFormProviderHeader", {
          ...receiptFormProviderHeader,
          val,
        });
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getReceiptFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      },
    },
    modalReceiptForm: {
      get() {
        return this.$store.getters["setModalReceiptForm"];
      },
      // set(value) {
      //   return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      // },
    },
    document: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    columnText() {
      if (this.document.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parendIdHide: {
      type: Number,
    },
    indexToFocus: {
      type: Number,
    },
  },
  watch: {
    indexToFocus(val) {
      if (val == this.index) {
        this.$nextTick(() => {
          this.$refs["fakeInputReceiptFormProvider" + this.index]?.focus({
            preventScroll: true,
          });
        });
      }
    },
  },
  methods: {
    orderFormProviderDetailTypeTranslate,
    ...mapActions([
      "addLineReceiptFormProvider",
      "deleteReceiptFormProviderDetail",
      "duplicateLineReceiptFormProvider",
      "updateReceiptFormProviderDetail",
    ]),
    openModal(isExisting) {
      if (isExisting) {
        this.$store.commit("SET_PRODUCT_TO_CREATE", this.line);
        this.$store.commit("SET_PRODUCT_PROVIDER_EXISTING", true);
        this.$store.commit("SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT", true);
      } else {
        this.$store.commit("SET_PRODUCT_TO_CREATE", this.line);
        this.$store.commit("SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT", true);
      }
    },
    productAlreadyExist(val) {
      return this.$store.getters.catalog.some(
        (elem) => elem.reference == val.reference
      );
    },
    changeEditFocus(val) {
      this.editFocus = val;
    },
    showProductDetails(line) {
      if (line.productId) {
        this.isOpen = true;
        this.$refs["sidebarProductContent"].getProductDetails(line.productId);
        this.$refs.sidebar.toggle();
      }
    },
    newLine(line) {
      let _this = this;
      this.addLineReceiptFormProvider({
        payload: {
          ...line,
          index: null,
          receiptFormProviderId: this.document.id,
        },
      });
      this.$nextTick(() => {
        _this.$refs["fakeInputReceiptFormProvider" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },
    async deleteLine(line) {
      let _this = this;
      await this.deleteReceiptFormProviderDetail({
        payload: [
          {
            receiptFormProviderDetailId: line.id,
            reviewIndex: true,
            line: line,
          },
        ],
      });
      this.$nextTick(() => {
        _this.$refs["fakeInputReceiptFormProvider" + this.index]?.focus({
          preventScroll: true,
        });
      });
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
  components: {
    ReceiptFormProviderDetailCommonLine,
    ReceiptFormProviderDetailSectionLine,
    ReceiptFormProviderDetailTextLine,
    ReceiptFormProviderDetailBreakPageLine,
    ReceiptFormProviderDetailBreakLine,
    ReceiptFormProviderDetailPriceLine,
    // ReceiptFormProviderDetailSubTotalLine,
    SidebarProductContent,
    "ejs-sidebar": SidebarComponent,
  },
};
</script>
<style>
.loading-overlay {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgb(255 255 255 / 75%);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
