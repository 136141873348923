<template lang="pug">
table.documentDetails   
  thead
    ReceiptFormProviderPreviewHeader()
  tbody 
    ReceiptFormProviderPreviewDetails(v-for="(line, index) in getReceiptFormProviderDetails" :key="index" :line="line" :index="index")
</template>
<script>
import ReceiptFormProviderPreviewHeader from "@/components/purchase/receipt-form-provider/preview/table/ReceiptFormProviderPreviewHeader.vue";
import ReceiptFormProviderPreviewDetails from "@/components/purchase/receipt-form-provider/preview/table/ReceiptFormProviderPreviewDetails.vue";
import { EDocumentTypeLine, EInputType } from "@/types/api-orisis/enums/enums";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getReceiptFormProviderDetails"]),
  },
  components: {
    ReceiptFormProviderPreviewHeader,
    ReceiptFormProviderPreviewDetails,
    draggable,
  },
};
</script>
