<template lang="pug"> 
  BreakPageCol
</template>

<script>
import BreakPageCol from "@/components/purchase/receipt-form-provider/builder/table/cols/BreakPageCol.vue";

export default {
  components: {
    BreakPageCol,
  },
};
</script>
