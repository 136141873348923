<template lang="pug">
div 
      b-row
        b-col(cols="12").col-lg-5
          ul
            li 
              h3.text-primary Bon de réception n° {{ receiptFormProvider.documentReference ?  receiptFormProvider.documentReference : 'en attente' }} 
                span(style='font-weight:300')
            li.d-flex.align-items-center
              Status(:statusId="receiptFormProvider.status" nature="receiptFormProvider" :static="true")
              SelectDate(keyValue="documentDate" @setValue="setDocumentDate" documentDetailKey="documentDate" text="En date du ")
            li(v-if="receiptFormProvider.orderFormId")
              | Sur commande fournisseur {{ initialOrderFormProvider.documentReference }} du {{ formatDate(receiptFormProvider.orderFormProviderDocumentDate) }}
            li.mt-2
              SelectReceiptDate(keyValue="receiptDate" @setValue="setDocumentReceiptDate" documentDetailKey="receiptDate" text="Réceptionné le " style="margin-bottom:5px")
            div(v-if="!receiptFormOption.hideUser")
              vs-divider.pl-0.mt-2.mb-50.text-primary(position="left") Traité par
              li
                UserInfoReceiptFormProvider(placeholder="Sélectionner un utilisateur" )
            div
              vs-divider.pl-0.mt-1.mb-50.text-primary(position="left") Associé à un client / une affaire 
              li
                SelectCompany(@setValue="setSelectCustomer" placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
              li
                SelectAffair(@setValue="setSelectAffair" placeholder="Sélectionner une affaire" :options='affairsList')
        b-col(cols="12").offset-lg-1.col-lg-6
          div
            vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Fournisseur
            div
              SelectProvider(@setValue="setSelectProvider" placeholder="Sélectionner un fournisseur" :options='companiesList.filter(elem => elem.companyType.id == 4 || elem.companyType.id == 8)')
          div(v-if="!receiptFormOption.hideStorageLocation")
            vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Point de stockage
            div
              selectStorageLocation(@setValue="setSelectStorageLocation" @setValueDeliverToAffairAddress="setValueDeliverToAffairAddress" placeholder="Sélectionner un point de stockage" :options='storageLocationsList')
      b-row
        b-col.mb-0(cols="12" class="align-items-center")
          span.text-primary.font-weight-bold.no-printting Rappel des articles
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Status from "@/components/global/status/Status";
var dayjs = require("dayjs");

import SelectCompany from "@/components/purchase/receipt-form-provider/builder/SelectCompany";
import SelectCustomer from "@/components/purchase/receipt-form-provider/builder/SelectCustomer";
import SelectProvider from "@/components/purchase/receipt-form-provider/builder/SelectProvider";
import SelectAffair from "@/components/purchase/receipt-form-provider/builder/SelectAffair";
import selectStorageLocation from "@/components/purchase/receipt-form-provider/builder/SelectStorageLocation";
import SelectDate from "@/components/purchase/receipt-form-provider/builder/SelectDate";
import SelectReceiptDate from "@/components/purchase/receipt-form-provider/builder/SelectReceiptDate";
import UserInfoReceiptFormProvider from "@/components/purchase/receipt-form-provider/builder/UserInfoReceiptFormProvider";
import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      collaboratorInfo: null,
    };
  },
  mounted() {
    this.setCollaboratorInfo();
  },
  methods: {
    ...mapActions([
      "updateReceiptForm",
      "getCompanyById",
      "getCollaboratorById",
    ]),
    natureTranslate,
    natureSimplifiedTranslate,
    formatDate(date) {
      return dayjs(date).format("DD/MM/YYYY");
    },
    setDocumentDate(date) {
      this.receiptFormProvider = {
        ...this.receiptFormProvider,
        documentDate: date,
      };
      this.saveAction();
    },
    setDocumentReceiptDate(date) {
      this.receiptFormProvider = {
        ...this.receiptFormProvider,
        receiptDate: date,
      };
      this.saveAction();
    },
    setDeliveryDate(date) {
      this.receiptFormProvider = {
        ...this.receiptFormProvider,
        deliveryDate: date,
      };
      this.saveAction();
    },
    setDocumentTechnicalVisitDate(date) {
      this.receiptFormProvider.quoteCEE = {
        ...this.receiptFormProvider.quoteCEE,
        technicalVisitDate: date,
      };
      this.saveAction();
    },
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    async setCollaboratorInfo() {
      if (this.receiptFormProvider.companyId) {
        await this.getCompanyById({
          companyId: this.receiptFormProvider.companyId,
        }).then(async (company) => {
          this.collaboratorInfo = company.collaboratorId
            ? await this.getCollaboratorById({
                collaboratorId: company.collaboratorId,
              })
            : null;
        });
      } else {
        this.collaboratorInfo = null;
      }
    },
    async setSelectProvider(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.receiptFormProvider = {
          ...this.receiptFormProvider,
          companyProviderId: company.id,
          companyProviderName: company.name,
          companyProviderAddress: company.address,
          companyProviderAddressComplement: company.addressComplement,
          companyProviderCity: company.city,
          companyProviderZipCode: company.zipCode,
          companyProviderCountry: company.country,
          companyProviderSiret: company.siret,
          companyProviderMail: company.email,
          companyProviderPhoneNumber: company.phoneNumber,
          companyProviderCode: company.code,
          companyProviderTva: company.tvaNumber,
          companyProviderAddressLabel: company.addressLabel,
        };
        this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.receiptFormProvider = {
                  ...this.receiptFormProvider,
                  companyProviderId: company.id,
                  companyProviderName: company.name,
                  companyProviderAddress: res.address,
                  companyProviderAddressComplement: res.addressComplement,
                  companyProviderCity: res.city,
                  companyProviderZipCode: res.zipCode,
                  companyProviderCountry: res.country,
                  companyProviderSiret: res.siret,
                  companyProviderMail: company.email,
                  companyProviderPhoneNumber: company.phoneNumber,
                  companyProviderCode: company.code,
                  companyProviderTva: company.tvaNumber,
                  companyProviderAddressLabel: res.label,
                };
              });
            } else {
              this.receiptFormProvider = {
                ...this.receiptFormProvider,
                companyProviderId: company.id,
                companyProviderName: company.name,
                companyProviderSiret: company.siret,
                companyProviderAddress: null,
                companyProviderAddressComplement: null,
                companyProviderCity: null,
                companyProviderZipCode: null,
                companyProviderCountry: null,
                companyProviderMail: company.email,
                companyProviderPhoneNumber: company.phoneNumber,
                companyProviderCode: company.code,
                companyProviderTva: null,
                companyProviderAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    async setSelectCustomer(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.receiptFormProvider = {
          ...this.receiptFormProvider,
          companyCustomerId: company.id,
          companyCustomerName: company.name,
          companyCustomerAddress: company.address,
          companyCustomerAddressComplement: company.addressComplement,
          companyCustomerCity: company.city,
          companyCustomerZipCode: company.zipCode,
          companyCustomerCountry: company.country,
          companyCustomerCode: company.code,
          companyCustomerPhoneNumber: company.phoneNumber,
          companyCustomerMail: company.email,
          companyCustomerSiret: company.siret,
          companyCustomerTva: company.tvaNumber,
          companyCustomerAddressLabel: company.addressLabel,
        };
        this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.receiptFormProvider = {
                  ...this.receiptFormProvider,
                  companyCustomerId: company.id,
                  companyCustomerName: company.name,
                  companyCustomerAddress: res.address,
                  companyCustomerAddressComplement: res.addressComplement,
                  companyCustomerCity: res.city,
                  companyCustomerZipCode: res.zipCode,
                  companyCustomerCountry: res.country,
                  companyCustomerCode: company.code,
                  companyCustomerPhoneNumber: company.phoneNumber,
                  companyCustomerMail: company.email,
                  companyCustomerSiret: res.siret,
                  companyCustomerTva: company.tvaNumber,
                  companyCustomerAddressLabel: res.label,
                };
              });
            } else {
              this.receiptFormProvider = {
                ...this.receiptFormProvider,
                companyCustomerId: company.id,
                companyCustomerName: company.name,
                companyCustomerAddress: null,
                companyCustomerAddressComplement: null,
                companyCustomerCity: null,
                companyCustomerZipCode: null,
                companyCustomerCountry: null,
                companyCustomerCode: company.code,
                companyCustomerPhoneNumber: company.phoneNumber,
                companyCustomerMail: company.email,
                companyCustomerSiret: null,
                companyCustomerTva: company.tvaNumber,
                companyCustomerAddressLabel: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    setValueDeliverToAffairAddress(value) {
      if (value) {
        this.receiptFormProvider.storageLocationId = null;
        this.receiptFormProvider.storageLocationLabel = null;
        this.receiptFormProvider.storageLocationAddress = null;
        this.receiptFormProvider.storageLocationAddressComplement = null;
        this.receiptFormProvider.storageLocationZipCode = null;
        this.receiptFormProvider.storageLocationCity = null;
        this.receiptFormProvider.storageLocationEmail = null;
        this.receiptFormProvider.storageLocationPhoneNumber = null;
        this.receiptFormProvider.storageLocationType = 0;
        let affair = this.affairsList.find(
          (el) => (el.id = this.receiptFormProvider.affairId)
        );
        if (affair && affair?.ownAddressAffair) {
          this.receiptFormProvider.affairAddress = affair.address;
          this.receiptFormProvider.affairAddressComplement =
            affair.addressComplement;
          this.receiptFormProvider.affairZipCode =
            affair.companyCustomerZipCode;
          this.receiptFormProvider.affairCity = affair.companyCustomerCity;
          this.receiptFormProvider.affairCountry =
            affair.companyCustomerCountry;
        } else {
          this.receiptFormProvider.affairAddress =
            this.receiptFormProvider.companyCustomerAddress;
          this.receiptFormProvider.affairAddressComplement =
            this.receiptFormProvider.companyCustomerAddressComplement;
          this.receiptFormProvider.affairZipCode =
            this.receiptFormProvider.companyCustomerZipCode;
          this.receiptFormProvider.affairCity =
            this.receiptFormProvider.companyCustomerCity;
          this.receiptFormProvider.affairCountry =
            this.receiptFormProvider.companyCustomerCountry;
        }
      }
      this.saveAction();
    },
    setSelectStorageLocation(storageLocation) {
      this.receiptFormProvider = {
        ...this.receiptFormProvider,
        storageLocationId: storageLocation.id,
        storageLocationLabel: storageLocation.label,
        storageLocationAddress: storageLocation.address,
        storageLocationAddressComplement: storageLocation.addressComplement,
        storageLocationZipCode: storageLocation.zipCode,
        storageLocationCity: storageLocation.city,
        storageLocationEmail: storageLocation.email,
        storageLocationPhoneNumber: storageLocation.phoneNumber,
        storageLocationType: storageLocation.storageLocationType,
      };
      this.saveAction();
    },
    setSelectAffair(affair) {
      if (this.receiptFormProvider.deliverToAffairAddress && !affair.address) {
        this.receiptFormProvider.affairId = affair.id;
        this.receiptFormProvider.affairName = affair.name;
        this.receiptFormProvider.affairAddress =
          this.receiptFormProvider.companyCustomerAddress;
        this.receiptFormProvider.affairAddressComplement =
          this.receiptFormProvider.companyCustomerAddressComplement;
        this.receiptFormProvider.affairZipCode =
          this.receiptFormProvider.companyCustomerZipCode;
        this.receiptFormProvider.affairCity =
          this.receiptFormProvider.companyCustomerCity;
        this.receiptFormProvider.affairCountry =
          this.receiptFormProvider.companyCustomerCountry;
        this.receiptFormProvider.affairCode = affair.code;
      } else {
        this.receiptFormProvider = {
          ...this.receiptFormProvider,
          affairId: affair.id,
          affairName: affair.name,
          affairAddress: affair.address,
          affairAddressComplement: affair.addressComplement,
          affairZipCode: affair.zipCode,
          affairCity: affair.city,
          affairCountry: affair.country,
          affairCode: affair.code,
        };
      }
      this.saveAction();
    },
    setDescription(description) {
      this.receiptFormProvider = {
        ...this.receiptFormProvider,
        description: description,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateReceiptForm({
        receiptFormProvider: this.receiptFormProvider,
        loading: false,
      });
    },
  },
  computed: {
    ...mapGetters([
      "companiesList",
      "affairsList",
      "storageLocationsList",
      "natureList",
      "initialOrderFormProvider",
      "receiptFormOption",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectCompany,
    SelectCustomer,
    SelectReceiptDate,
    SelectProvider,
    SelectAffair,
    selectStorageLocation,
    SelectDate,
    UserInfoReceiptFormProvider,
    Status,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
