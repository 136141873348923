<template lang="pug"> 
  td
    div(v-if="receiptFormProviderHeader[dataKey].inputType == 1") 
      span {{receiptFormProviderHeader[dataKey].prefix }} 
      span {{receiptFormProviderHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(receiptFormProviderHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{receiptFormProviderHeader[dataKey].unit}}
    div(v-else-if="receiptFormProviderHeader[dataKey].inputType == 25")  
      span(:style="'color:'+receiptFormProvider.institutionColor+' !important'") {{line[dataKey]}}
</template>
<script>
import { mapGetters } from "vuex";
import TitleCol from "@/components/purchase/receipt-form-provider/builder/table/cols/TitleCol.vue";
import TextCol from "@/components/purchase/receipt-form-provider/builder/table/cols/TextCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    receiptFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["receiptFormProvider"]),
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TitleCol,
    TextCol,
  },
};
</script>
