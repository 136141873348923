<template lang="pug">
tr(:class="line.isOption ? 'isOption' : ''" :style="line.type == 2 ? 'page-break-after: always;' : ''")
  ReceiptFormProviderPreviewDetailCommonLine.m-0(v-for="(key, i) in Object.keys(receiptFormProviderHeader)" :key="i"  :dataKey="key" v-if="receiptFormProviderHeader[key].display && [7,8,9,10].includes(line.type)" :class="receiptFormProviderHeader[key].class" :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :editFocus="editFocus" :edit="edit") 
  ReceiptFormProviderPreviewDetailSectionLine.m-0(v-for="(key, i) in columnTitle" :key="i"  v-if="line.type == 1 || line.type == 2" :line="line" :class="receiptFormProviderHeader[key].class" :colspan="setColspanSection(receiptFormProviderHeader[key])" :receiptFormProviderHeader="receiptFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  //- ReceiptFormProviderPreviewDetailSubTotalLine.m-0(v-for="(key, i) in ['index', 'description', 'subtotal']" :key="i" :indexArray="index-1" v-if="line.type == 5" :line="line" :class="receiptFormProviderHeader[key].class" :colspan="setColspanSubTotalLine(receiptFormProviderHeader[key])"  :receiptFormProviderHeader="receiptFormProviderHeader" :dataKey="key" :editFocus="editFocus" :edit="edit")
  ReceiptFormProviderPreviewDetailTextLine.m-0(v-for="(key, i) in columnText" :key="i"  :dataKey="key" v-if="line.type == 6" :class="receiptFormProviderHeader[key].class" :colspan="setColspanSection(receiptFormProviderHeader[key])" :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :editFocus="editFocus" :edit="edit")
  ReceiptFormProviderPreviewDetailBreakLine.m-0(v-for="(key, i) in ['description']" :key="i"  :dataKey="key" v-if="line.type == 3"  :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :colspan="setColspanAll(receiptFormProviderHeader[key])" :edit="edit" :editFocus="editFocus")
  //- ReceiptFormProviderPreviewDetailPriceLine.m-0(v-for="(key, i) in ['index', 'description']" :key="i"  :dataKey="key" v-if="receiptFormProviderHeader[key].display" :class="receiptFormProviderHeader[key].class" :line="line" :receiptFormProviderHeader="receiptFormProviderHeader" :editFocus="editFocus" :edit="edit")
</template>
<script>
import ReceiptFormProviderPreviewDetailCommonLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailCommonLine.vue";
import ReceiptFormProviderPreviewDetailSectionLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailSectionLine.vue";
import ReceiptFormProviderPreviewDetailTextLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailTextLine.vue";
import ReceiptFormProviderPreviewDetailBreakPageLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailBreakPageLine.vue";
import ReceiptFormProviderPreviewDetailBreakLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailBreakLine.vue";
import ReceiptFormProviderPreviewDetailPriceLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailPriceLine.vue";
// import ReceiptFormProviderPreviewDetailSubTotalLine from "@/components/purchase/receipt-form-provider/preview/table/lines/ReceiptFormProviderPreviewDetailSubTotalLine.vue";
export default {
  data() {
    return {
      edit: false,
      editFocus: false,
    };
  },
  computed: {
    receiptFormProviderHeader: {
      get() {
        return this.$store.getters.getReceiptFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setReceiptFormProviderHeader", {
          ...receiptFormProviderHeader,
          val,
        });
      },
    },
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    columnText() {
      if (this.receiptFormProvider.showReferenceColumn) {
        return ["index", "reference", "editor"];
      } else {
        return ["index", "editor"];
      }
    },
    columnTitle() {
      return ["index", "titre"];
    },
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },

  watch: {
    editFocus(val) {
      if (val == false) {
        this.edit = false;
      }
    },
  },
  methods: {
    setColspanAll(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return Object.entries(this.receiptFormProviderHeader).filter(
          (el) => el[1].display == true
        ).length;
      } else {
        return 1;
      }
    },
    setColspanSection(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.receiptFormProviderHeader).filter(
            (el) => el[1].display == true
          ).length - 1
        );
      } else {
        return 1;
      }
    },
    setColspanSubTotalLine(key) {
      if (key.text == "Désignation" || key.text == "Titre") {
        return (
          Object.entries(this.receiptFormProviderHeader).filter(
            (el) => el[1].display == true
          ).length - 2
        );
      } else {
        return 1;
      }
    },
  },
  components: {
    ReceiptFormProviderPreviewDetailCommonLine,
    ReceiptFormProviderPreviewDetailSectionLine,
    ReceiptFormProviderPreviewDetailTextLine,
    ReceiptFormProviderPreviewDetailBreakPageLine,
    ReceiptFormProviderPreviewDetailBreakLine,
    ReceiptFormProviderPreviewDetailPriceLine,
    // ReceiptFormProviderPreviewDetailSubTotalLine,
  },
};
</script>
