<template lang="pug">
.quote-card(v-if="!preview && !receiptFormProvider.orderFormId")
  b-form-group(v-if="!receiptFormProvider.companyProviderId" label-for='provider')
    validation-provider(#default='{ errors }' name='Provider')
      v-select#company(ref="selectCompany" :loading='isLoadingCompaniesList' :state='errors.length > 0 ? false : null' :value='receiptFormProvider.companyProviderName' @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='options.map((elem)=>{return {label:elem.name, value:elem.id}})' :placeholder='placeholder')
        template(v-slot:no-options='')
          template  Aucun r&eacute;sultat trouv&eacute;
        li.border-bottom.p-1.py-50(slot='list-header')
          b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newProvider()' variant='primary' size='sm')
            | + Nouveau fournisseur
            span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
              | open_in_new
      small.text-danger {{ errors[0] }}
  div(v-if="editMode")
    div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
      span.text-primary.font-weight-bold Fournisseur :
      span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
    .mb-1
      b-form-group(label-for='companyName' style='flex:1')
        validation-provider(#default='{ errors }' name='companyName')
          b-form-input(placeholder="Nom de la société" v-model="receiptFormProviderEdit.companyProviderName")
    div.mb-1(v-if="company && company.addresses && company.addresses.length")
      b-form-group(v-if="receiptFormProviderEdit.companyProviderId" label-for='address')
        validation-provider(#default='{ errors }' name='address')
          v-select#addresses(ref="selectAddress" item-text="label" :loading='isLoadingCompany' :state='errors.length > 0 ? false : null' :value='receiptFormProviderEdit.companyProviderAddressLabel' @input='setCompanyProviderAddress' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='company.addresses' placeholder='Sélectionner une adresse')
            template(v-slot:no-options='')
              template  Aucun r&eacute;sultat trouv&eacute;
    .mb-1
      b-form-group(label-for='companyCode' style='flex:1')
        validation-provider(#default='{ errors }' name='companyCode')
          b-form-input(placeholder="code de la société" v-model="receiptFormProviderEdit.companyProviderCode")
    .mb-1
      b-form-group(label-for='companyAddress' style='flex:1')
        validation-provider(#default='{ errors }' name='companyAddress')
          b-form-input(placeholder="Adresse" v-model="receiptFormProviderEdit.companyProviderAddress") 
    .mb-1
      b-form-group(label-for='companyAddressComplement' style='flex:1')
        validation-provider(#default='{ errors }' name='companyAddressComplement')
          b-form-input(placeholder="Complément d'adresse" v-model="receiptFormProviderEdit.companyProviderAddressComplement") 
    .mb-1.d-flex
      .w-25
        b-form-group.pr-1(label-for='companyZipCode' style='flex:1')
          validation-provider(#default='{ errors }' name='companyZipCode')
            b-form-input(placeholder="CP"  v-model="receiptFormProviderEdit.companyProviderZipCode")
      .w-75
        b-form-group(label-for='companyCity' style='flex:1')
          validation-provider(#default='{ errors }' name='companyCity')
            b-form-input(placeholder="Ville" v-model="receiptFormProviderEdit.companyProviderCity")
    .mb-1
      b-form-group(label-for='companyCountry' style='flex:1')
        validation-provider(#default='{ errors }' name='companyCountry')
          b-form-input(placeholder="Pays" v-model="receiptFormProviderEdit.companyProviderCountry") 
    .mb-1
      b-form-group(label-for='companyMail' style='flex:1')
        validation-provider(#default='{ errors }' name='companyMail')
          b-form-input(placeholder="Email" v-model="receiptFormProviderEdit.companyProviderMail") 
    .mb-1
      b-form-group(label-for='companyPhoneNumber' style='flex:1')
        validation-provider(#default='{ errors }' name='companyPhoneNumber')
          b-form-input(placeholder="Téléphone" v-model="receiptFormProviderEdit.companyProviderPhoneNumber")
    .mb-1
      b-form-group(label-for='companySiret' style='flex:1')
        validation-provider(#default='{ errors }' name='companySiret')
          b-form-input(placeholder="Siret" v-model="receiptFormProviderEdit.companyProviderSiret") 
    .mb-1(v-if="receiptFormOption.showCompanyProviderTvaNumber") 
      b-form-group(label-for='companyTva' style='flex:1')
        validation-provider(#default='{ errors }' name='companyTva')
        b-form-input(placeholder="N° TVA intra" v-model="receiptFormProviderEdit.companyProviderTva")
  .card-info(v-else-if="receiptFormProvider.companyProviderId" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
    div(v-if="!editMode")
      .card-tools.pr-0(v-if="showCardTool")
        feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le fournisseur'" @click="setEditMode()")
        feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de fournisseur'")
      p.mb-50.text-primary.font-weight-bold {{ receiptFormProvider.companyProviderCode && receiptFormOption.showCompanyProviderCode ? receiptFormProvider.companyProviderCode + " - " : "" }} {{ receiptFormProvider.companyProviderName  }} 
      div(v-if="receiptFormOption.showCompanyProviderAddressLabel && receiptFormProvider.companyProviderAddressLabel != null")
        span {{ receiptFormProvider.companyProviderAddressLabel }}
      div(v-if="receiptFormProvider.companyProviderAddress != null")
        span {{ receiptFormProvider.companyProviderAddress }}
      div(v-if="receiptFormProvider.companyProviderAddressComplement != null")
        span {{ receiptFormProvider.companyProviderAddressComplement }}
      div(v-if="receiptFormProvider.companyProviderZipCode != null || receiptFormProviderEdit.companyProviderCity != null")
        span {{ receiptFormProvider.companyProviderZipCode }} {{ receiptFormProviderEdit.companyProviderCity }}
      div(v-if="receiptFormProvider.companyProviderCountry != null")
        span {{ receiptFormProvider.companyProviderCountry }}
      div(v-if="receiptFormOption.showCompanyProviderMail")
        span E-mail : {{ receiptFormProvider.companyProviderMail ? receiptFormProvider.companyProviderMail : 'Non renseigné' }}
      div(v-if="receiptFormOption.showCompanyProviderPhoneNumber")
        span Téléphone : {{ receiptFormProvider.companyProviderPhoneNumber ? receiptFormProvider.companyProviderPhoneNumber : 'Non renseigné' }}
      div(v-if="receiptFormOption.showCompanyProviderSiret")
        span Siret : {{ receiptFormProvider.companyProviderSiret ? receiptFormProvider.companyProviderSiret : 'Non renseigné' }}
      div(v-if="receiptFormOption.showCompanyProviderTvaNumber")
        span N° TVA intra : {{ receiptFormProvider.companyProviderTva ? receiptFormProvider.companyProviderTva : 'Non renseigné' }}
.quote-card(v-else)
  .card-info
    p.mb-50.text-primary.font-weight-bold {{ receiptFormProvider.companyProviderCode && receiptFormOption.showCompanyProviderCode ? receiptFormProvider.companyProviderCode + " - " : "" }} {{ receiptFormProvider.companyProviderName  }} 
    div(v-if="receiptFormOption.showCompanyProviderAddressLabel && receiptFormProvider.companyProviderAddressLabel != null")
      span {{ receiptFormProvider.companyProviderAddressLabel }}
    div(v-if="receiptFormProvider.companyProviderAddress != null")
      span {{ receiptFormProvider.companyProviderAddress }}
    div(v-if="receiptFormProvider.companyProviderAddressComplement != null")
      span {{ receiptFormProvider.companyProviderAddressComplement }}
    div(v-if="receiptFormProvider.companyProviderZipCode != null || receiptFormProvider.companyProviderCity != null")
      span {{ receiptFormProvider.companyProviderZipCode }} {{ receiptFormProvider.companyProviderCity }}
    div(v-if="receiptFormProvider.companyProviderCountry != null")
      span {{ receiptFormProvider.companyProviderCountry }}
    div(v-if="receiptFormOption.showCompanyProviderMail")
      span E-mail : {{ receiptFormProvider.companyProviderMail ? receiptFormProvider.companyProviderMail : 'Non renseigné'}}
    div(v-if="receiptFormOption.showCompanyProviderPhoneNumber")
      span Tél : {{ receiptFormProvider.companyProviderPhoneNumber ? receiptFormProvider.companyProviderPhoneNumber : 'Non renseigné'}}
    div(v-if="receiptFormOption.showCompanyProviderSiret")
      span Siret : {{ receiptFormProvider.companyProviderSiret ? receiptFormProvider.companyProviderSiret : 'Non renseigné'}}
    div(v-if="receiptFormOption.showCompanyProviderTvaNumber")
      span N° TVA intra : {{ receiptFormProvider.companyProviderTva ? receiptFormProvider.companyProviderTva : 'Non renseigné'}}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    mask,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      receiptFormProviderEdit: {},
      number: null,
    };
  },
  created() {
    if (this.receiptFormProvider.companyProviderId) {
      this.$store
        .dispatch("getCompanyById", {
          companyId: this.receiptFormProvider.companyProviderId,
        })
        .then((res) => {
          this.company = res;
        });
    }
  },
  computed: {
    ...mapGetters([
      "isLoadingCompaniesList",
      "receiptFormOption",
      "isLoadingCompany",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    receiptFormProviderDetails: {
      get() {
        return this.$store.getters.getReceiptFormProviderDetails;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      },
    },
  },
  methods: {
    ...mapActions(["getCompanies", "deleteReceiptFormProviderDetail"]),
    validModal(editCompany) {
      if (editCompany) {
        const updateData = {
          id: this.receiptFormProvider.companyProviderId,
          name: this.receiptFormProviderEdit.companyProviderName,
          firstName: this.receiptFormProviderEdit.companyProviderFirstName,
          lastName: this.receiptFormProviderEdit.companyProviderLastName,
          address: this.receiptFormProviderEdit.companyProviderAddress,
          addressComplement:
            this.receiptFormProviderEdit.companyProviderAddressComplement,
          zipCode: this.receiptFormProviderEdit.companyProviderZipCode,
          city: this.receiptFormProviderEdit.companyProviderCity,
          country: this.receiptFormProviderEdit.companyProviderCountry,
          phoneNumber: this.receiptFormProviderEdit.companyProviderPhoneNumber,
          mail: this.receiptFormProviderEdit.companyProviderMail,
          tvaNumber: this.receiptFormProviderEdit.companyProviderTva,
          siret: this.receiptFormProviderEdit.companyProviderSiret,
        };
        this.$store
          .dispatch("getCompanyById", {
            companyId: this.receiptFormProvider.companyProviderId,
          })
          .then((res) => {
            this.$store.dispatch("updateCompany", {
              company: {
                ...res,
                ...updateData,
                companyTypeId: res.companyType.id,
              },
            });
          });
      }
      this.receiptFormProvider = this.receiptFormProviderEdit;
      this.editMode = false;
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy receiptFormProvider into new variable
      this.receiptFormProviderEdit = JSON.parse(
        JSON.stringify(this.receiptFormProvider)
      );
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    setCompanyProviderAddress(value) {
      if (value) {
        this.receiptFormProviderEdit.companyProviderAddressId = value.id;
        this.receiptFormProviderEdit.companyProviderAddressLabel = value.label;
        this.receiptFormProviderEdit.companyProviderAddress = value.address;
        this.receiptFormProviderEdit.companyProviderAddressComplement =
          value.addressComplement;
        this.receiptFormProviderEdit.companyProviderZipCode = value.zipCode;
        this.receiptFormProviderEdit.companyProviderCity = value.city;
        this.receiptFormProviderEdit.companyProviderCountry = value.country;
        this.receiptFormProviderEdit.companyProviderSiret = value.siret;
        this.receiptFormProviderEdit.companyProviderTva =
          this.receiptFormProvider.companyProviderTva;
        this.receiptFormProviderEdit.companyProviderPhoneNumber =
          this.receiptFormProvider.companyProviderPhoneNumber;
        this.receiptFormProviderEdit.companyProviderMail =
          this.receiptFormProvider.companyProviderMail;
        this.receiptFormProviderEdit.companyProviderCode =
          this.receiptFormProvider.companyProviderCode;
      } else {
        this.receiptFormProviderEdit.companyProviderAddressId = null;
        this.receiptFormProviderEdit.companyProviderAddressLabel = null;
        this.receiptFormProviderEdit.companyProviderAddress = null;
        this.receiptFormProviderEdit.companyProviderAddressComplement = null;
        this.receiptFormProviderEdit.companyProviderZipCode = null;
        this.receiptFormProviderEdit.companyProviderCity = null;
        this.receiptFormProviderEdit.companyProviderCountry = null;
        this.receiptFormProviderEdit.companyProviderSiret = null;
        this.receiptFormProviderEdit.companyProviderTva = null;
        this.receiptFormProviderEdit.companyProviderPhoneNumber = null;
        this.receiptFormProviderEdit.companyProviderMail = null;
        this.receiptFormProviderEdit.companyProviderCode = null;
      }
    },
    resetValue() {
      if (
        this.receiptFormProviderDetails.find(
          (elem) => elem.productProviderId != null
        )
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, les produits sélectionnés seront enlevés du document si vous changez de fournisseur",
            {
              title: "Souhaitez-vous changer de fournisseur ?",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, confirmer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              //Tri des orderform details et on enlève les lignes avec des produits liés
              const detailsWithProduct = this.receiptFormProviderDetails
                .filter((elem) => elem.productProviderId != null)
                .map((elem) => {
                  return {
                    line: elem,
                    receiptFormProviderDetailId: elem.id,
                    reviewIndex: true,
                  };
                });
              await this.deleteReceiptFormProviderDetail({
                payload: detailsWithProduct,
              });
              let value = {
                id: null,
                name: null,
                firstName: null,
                lastName: null,
                address: null,
                addressComplement: null,
                city: null,
                zipCode: null,
                country: null,
                email: null,
                phoneNumber: null,
                tvaNumber: null,
                siret: null,
              };
              this.value = value;
              this.setValue(value);
              this.receiptFormProvider.companyProviderId = null;
              this.receiptFormProvider.companyProviderName = null;
            }
          });
      } else {
        let value = {
          id: null,
          name: null,
          firstName: null,
          lastName: null,
          address: null,
          addressComplement: null,
          city: null,
          zipCode: null,
          country: null,
          email: null,
          phoneNumber: null,
          tvaNumber: null,
          siret: null,
        };
        this.value = value;
        this.setValue(value);
        this.receiptFormProvider.companyProviderId = 0;
        this.receiptFormProvider.companyProviderName = null;
      }
      // this.$refs['selectCompany'].reset();
    },
    newProvider() {
      this.$router.push({ path: "/directory/new-company/fournisseurs/4" });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
