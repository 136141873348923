<template lang="pug">
  .quote-card(v-if="!preview")
    b-form-group(v-if="!document.storageLocationId && !document.deliverToAffairAddress" label-for='storage-location')
      validation-provider(#default='{ errors }' name='storage-location')
        v-select#company(ref="selectStorageLocation" :loading='isLoadingStorageLocationsList' :state='errors.length > 0 ? false : null'  @input='setValue' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='options.map((elem)=>{return {label:elem.label, value:elem.id}})' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute;
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(@click='newStorageLocation()' variant='primary' size='sm')
              | + Nouveau point de stockage
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
        .d-flex.justify-content-end.mr-1.mt-1(v-show="document.affairId") 
          label.mr-0(for="deliverToAffairAddressStorageLocation" )
            em Reçu sur le lieu de l'affaire
          b-form-checkbox#deliverToAffairAddressStorageLocation.ml-1.mr-n2(@input='setValueDeliverToAffairAddress' v-model='document.deliverToAffairAddress')
    div(v-if="editMode")
      div.pb-1.d-flex(:class="editMode ? 'justify-content-between' : ''")
        span.text-primary.font-weight-bold Adresse de réception :
        span.cursor-pointer.text-primary(v-if="editMode" @click='validEditMode') Enregistrer
      div.mb-1
        b-form-group(label-for='storageLocationLabel' style='flex:1')
          validation-provider(#default='{ errors }' name='storageLocationLabel')
            b-form-input(placeholder="Nom du point de stockage" v-model="documentEdit.storageLocationLabel")
      div.mb-1
        b-form-group(label-for='storageLocationAddress' style='flex:1')
          validation-provider(#default='{ errors }' name='storageLocationAddress')
            b-form-input(placeholder="Adresse" v-model="documentEdit.storageLocationAddress") 
      div.mb-1
        b-form-group(label-for='storageLocationAddressComplement' style='flex:1')
          validation-provider(#default='{ errors }' name='storageLocationAddressComplement')
            b-form-input(placeholder="Adresse" v-model="documentEdit.storageLocationAddressComplement") 
      div.mb-1.d-flex
        div.w-25
          b-form-group.pr-1(label-for='storageLocationZipCode' style='flex:1')
            validation-provider(#default='{ errors }' name='storageLocationZipCode')
              b-form-input(placeholder="CP"  v-model="documentEdit.storageLocationZipCode")
        div.w-75
          b-form-group(label-for='storageLocationCity' style='flex:1')
            validation-provider(#default='{ errors }' name='storageLocationCity')
              b-form-input(placeholder="Ville" v-model="documentEdit.storageLocationCity")
      div.mb-1
        b-form-group(label-for='storageLocationEmail' style='flex:1')
          validation-provider(#default='{ errors }' name='storageLocationEmail')
            b-form-input(placeholder="Adresse" v-model="documentEdit.storageLocationEmail") 
      div.mb-1
        b-form-group(label-for='storageLocationPhone' style='flex:1')
          validation-provider(#default='{ errors }' name='storageLocationPhone')
            b-form-input(placeholder="Numéro de téléphone" v-model="documentEdit.storageLocationPhoneNumber") 
    .card-info(v-else-if="document.storageLocationId && !document.deliverToAffairAddress" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode")
        .card-tools.pr-0(v-if="showCardTool")
          feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier le point de stockage'" @click="setEditMode()")
          feather-icon(icon="XIcon" size="18" @click="resetValue"  v-b-tooltip.hover.top="'Changer de point de stockage'")
        p.mb-50.text-primary.font-weight-bold {{ document.storageLocationLabel  }}
        div(v-if="document.storageLocationAddress != null")
          span {{ document.storageLocationAddress }}
        div(v-if="document.storageLocationAddressComplement != null")
          span {{ document.storageLocationAddressComplement }}
        div(v-if="document.storageLocationZipCode != null || document.storageLocationCity != null")
          span {{ document.storageLocationZipCode }} {{ document.storageLocationCity }}
      .d-flex.justify-content-end.mr-1( v-show="document.affairId")
        label.mr-0(for="deliverToAffairAddressStorageLocation")
          em Reçu sur le lieu de l'affaire
        b-form-checkbox#deliverToAffairAddressStorageLocation.ml-1.mr-n2(@input='setValueDeliverToAffairAddress' v-model='document.deliverToAffairAddress')
    .card-info(v-else-if="document.deliverToAffairAddress" @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      div(v-if="!editMode && document.affairId")
        p.mb-50.text-primary.font-weight-bold {{ document.affairName  }}
        div(v-if="document.affairAddress != null")
          span {{ document.affairAddress }}
        div(v-if="document.affairAddressComplement != null")
          span {{ document.affairAddressComplement }}
        div(v-if="document.affairZipCode != null || document.affairCity != null")
          span {{ document.affairZipCode }} {{ document.affairCity }}
        div(v-if="document.affairCountry != null")
          span {{ document.affairCountry }}
      div(v-else)
        span Aucune affaire séléctionnée
      .d-flex.justify-content-end.mr-1( v-show="document.affairId")
        label.mr-0(for="deliverToAffairAddressAffair")
          em Reçu sur le lieu de l'affaire
        b-form-checkbox#deliverToAffairAddressAffair.ml-1.mr-n2(@input='setValueDeliverToAffairAddress' v-model='document.deliverToAffairAddress')
  .quote-card(v-else)
    .card-info(v-if="!document.deliverToAffairAddress")
      p.mb-50.text-primary.font-weight-bold {{ document.storageLocationLabel  }}
      div(v-if="document.storageLocationAddress != null")
        span {{ document.storageLocationAddress }}
      div(v-if="document.storageLocationAddressComplement != null")
        span {{ document.storageLocationAddressComplement }}
      div(v-if="document.storageLocationZipCode != null || document.storageLocationCity != null")
        span {{ document.storageLocationZipCode }} {{ document.storageLocationCity }}
    .card-info(v-else)
      p.mb-50.text-primary.font-weight-bold {{ document.affairName  }}
      div(v-if="document.affairAddress != null")
        span {{ document.affairAddress }}
      div(v-if="document.affairAddressComplement != null")
        span {{ document.affairAddressComplement }}
      div(v-if="document.affairZipCode != null || document.affairCity != null")
        span {{ document.affairZipCode }} {{ document.affairCity }}
      div(v-if="document.affairCountry != null")
        span {{ document.affairCountry }}
</template>

<script>
import vSelect from "vue-select";
import { storageLocationTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      showCardTool: false,
      required,
      editMode: false,
      documentEdit: {},
    };
  },
  created() {
    this.getStorageLocations({});
  },
  computed: {
    ...mapGetters(["isLoadingStorageLocationsList"]),
    document: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    storageLocationTypeTranslate,
    ...mapActions(["getStorageLocations"]),
    validModal(editCompany) {
      this.document = this.documentEdit;
      this.editMode = false;
    },
    validEditMode() {
      this.validModal(false);
    },
    setEditMode() {
      this.editMode = true;
      // copy document into new variable
      this.documentEdit = JSON.parse(JSON.stringify(this.document));
    },
    setValueDeliverToAffairAddress(value) {
      this.$emit("setValueDeliverToAffairAddress", value);
    },
    setValue(value) {
      if (value.id !== 0 && value.id !== null) {
        this.$emit(
          "setValue",
          this.options.find((elem) => {
            return elem.id == value.value;
          })
        );
      } else {
        this.$emit("setValue", value);
      }
    },
    resetValue() {
      // this.$refs['selectCompany'].reset();
      let value = {
        id: null,
        label: null,
        address: null,
        addressComplement: null,
        city: null,
        zipCode: null,
        email: null,
        phoneNumber: null,
      };
      this.value = value;
      this.setValue(value);
      this.document.storageLocationId = null;
      this.document.storageLocationLabel = null;
    },
    newStorageLocation() {
      this.$router.push({
        name: "new-storage-location",
        params: {
          routeOrigine: "/new-receipt-form-provider/" + this.document.id,
        },
      });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>
