<template lang="pug">
div.h-100
  b-row.my-0.h-100
    b-col.py-0.mb-1.h-100(cols='12')
      quill-editor.h-100.quill-editor-scroll(class="editor quill-fixed" @blur="saveAction" v-model="receiptFormProvider.internalNote" :options="editorOption")
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      editorOption: {
        placeholder: "Ecrivez une note interne liée à ce document ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" },
            ],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
        },
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateReceiptForm"]),
    saveAction() {
      this.updateReceiptForm({
        receiptFormProvider: this.receiptFormProvider,
        loading: false,
      });
    },
  },
};
</script>
