<template lang="pug">
div.builder-header.px-2 
  div
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(v-if="(receiptFormProvider.status < 3 && (initialOrderFormProvider.length > 0 && initialOrderFormProvider.receiptFormProviders[initialOrderFormProvider.receiptFormProviders.length - 1].receiptFormStep <= receiptFormProvider.receiptFormStep)) || initialOrderFormProvider.status !== 8" @click="changeReceiptFormProviderTabActive('receipt-form-provider-edit')")
        div.nav-link.cursor-pointer.text-center(:class="receiptFormProviderTabActive == 'receipt-form-provider-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15") 
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="receiptFormProviderTabActive == 'receipt-form-provider-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15") 
          |  Prévisualisation
      li.nav-item(v-if="haveGedModule" @click="changeReceiptFormProviderTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="receiptFormProviderTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15") 
          |  Documents
      li.nav-item(@click="changeReceiptFormProviderTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="receiptFormProviderTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15") 
          |  Notes
  div.d-flex.align-items-center.ml-auto
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="receiptFormProviderTabActive=='receipt-form-provider-edit'" data-tab='order-form-provider-edit')
      .d-flex.align-items-center
        b-dropdown.mr-1#dropdown-form(ref='dropdown' right='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6 Edition  
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.showReferenceColumn")
              | Afficher la colonne des références
            h6.mt-1 Prévisualisation 
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideInstitutionLogo")
              | Masquer mon logo
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideInstitutionName")
              | Masquer ma raison sociale
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideInstitutionAddress")
              | Masquer mon adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideAffair")
              | Masquer l'affaire
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showAffairCode")
              | Masquer le code de l'affaire
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideStorageLocation")
              | Masquer le point de stockage
            h6.mt-1 Document
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideUser")
              | Masquer le contact
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideCollaborator")
              | Masquer le collaborateur
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.hideIndexColumn")
              | Masquer la colonne des index
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.hideReceivedQuantityColumn")
              | Masquer la colonne des quantités reçues
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.hideOrderedQuantityColumn")
              | Masquer la colonne des quantités commandées
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.hidePreviousQuantitiesReceived")
              | Masquer la colonne des quantités déjà reçues
            b-form-checkbox.checkbox-options(@change="setHeader" v-model="receiptFormOption.hideUnitColumn")
              | Masquer la colonne unités
            h6.mt-1 Client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideCompanyCustomer")
              | Masquer le client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerCode")
              | Afficher le code du client
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerMail")
              | Afficher l'adresse mail
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyCustomerTvaNumber")
              | Afficher le numéro de TVA intracommunautaire
            h6.mt-1 Fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.hideCompanyProvider")
              | Masquer le fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderCode")
              | Afficher le code du fournisseur
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderAddressLabel")
              | Afficher le libellé de l'adresse
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderMail")
              | Afficher l'adresse mail
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderPhoneNumber")
              | Afficher le numéro de téléphone
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderSiret")
              | Afficher le numéro de SIRET
            b-form-checkbox.checkbox-options(@change="saveAction(false)" v-model="receiptFormOption.showCompanyProviderTvaNumber")
              | Afficher le numéro de TVA intracommunautaire
    div(v-if="receiptFormProvider.status == 0 || receiptFormProvider.status == 1")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" variant="outline-danger")
        | Supprimer
    ejs-tooltip.tooltipcontainer(opensOn='Custom' v-on:mouseover.native='customOpen' v-on:mouseleave.native='customClose' content="Certaines lignes ne sont pas valides" :cssClass="cssClass" ref='tooltip')
      div.d-flex.align-items-center.ml-auto(v-if="(receiptFormProvider.status < 3) && receiptFormProviderDetails.length > 0")
        b-button-group.mr-1
          b-dropdown.dropdown-options(text='Finaliser le document' variant='success' dropup :disabled="productListIncomplete")
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(1, false)" :disabled="receiptFormProvider.status >= 2" :style="receiptFormProvider.status >= 2 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-warning
                span.label.text-warning Passer en validation
            b-dropdown-item.cursor-pointer(@click="finalizedDocument(2, false)" :disabled="receiptFormProvider.status >= 3" :style="receiptFormProvider.status >= 3 ? 'opacity:0.5' : ''") 
              span.chip.justify-content-start
                span.point.bg-primary
                span.label.text-primary Valider la réception
    div
      b-button-group(v-if="receiptFormProvider.status < 3")
        b-button.btn-invoice-builder-header.m-0(@click="checkStatusBeforeSave(false)" variant='primary')
          | Enregistrer
        b-button.btn-invoice-builder-header.bl-white(@click="checkStatusBeforeSave(true)" style="border-left: 1px solid #ccc !important;" variant='primary')
          feather-icon(icon='LogOutIcon')
      b-button-group(v-else-if="receiptFormProvider.path")
        b-button.btn-invoice-builder-header.m-0(variant='primary' @click="forceFileDownload(receiptFormProvider.path)")
          | Télécharger
          feather-icon.ml-50(icon='DownloadIcon')
        b-button.btn-invoice-builder-header.bl-white(variant='primary' @click="cancel" style="border-left: 1px solid #f1f1f1 !important; border-top-left-radius:0px; border-bottom-left-radius:0px; ")
          | Fermer 
          feather-icon.ml-50(icon='LogOutIcon')
      b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel")
        | Fermer 
        feather-icon.ml-50(icon='LogOutIcon')
</template>
<script>
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";

import {
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import axios from "axios";
import {
  checkMandatoryInformation,
  executeMultipleAsyncFunctions,
} from "@/types/api-orisis/library/ReceiptFormProviderOperations";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  data() {
    return {
      cssClass: "tool-tip-error",
    };
  },
  computed: {
    ...mapGetters([
      "receiptFormProviderTabActive",
      "receiptFormProviderPDF",
      "workspaceSelected",
      "initialOrderFormProvider",
      "receiptFormOption",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    receiptFormProviderDetails: {
      get() {
        return this.$store.getters["getReceiptFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      },
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
    productListIncomplete() {
      for (let i = 0; i < this.receiptFormProviderDetails.length; i++) {
        const receiptFormProviderDetail = this.receiptFormProviderDetails[i];
        if (
          (!receiptFormProviderDetail.productId ||
            !receiptFormProviderDetail.productProviderId ||
            !receiptFormProviderDetail.reference ||
            !receiptFormProviderDetail.description) &&
          [7, 8, 9, 10].includes(receiptFormProviderDetail.type)
        ) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.setupHeaderReceiptFormProvider();
  },
  methods: {
    ...mapActions([
      "createReceiptFormProvider",
      "updateReceiptForm",
      "deleteReceiptFormProviders",
      "changeReceiptFormProviderTabActive",
      "setupHeaderReceiptFormProvider",
      "createProduct",
      "createProductProvider",
      "updateProductProvider",
      "updateReceiptFormProviderDetail",
      "updateReceiptFormOption",
    ]),
    checkFunctionUser,
    saveAction(bool) {
      this.updateReceiptFormOption({
        receiptFormOption: this.receiptFormOption,
      });
    },
    async setHeader() {
      this.setupHeaderReceiptFormProvider();
      this.saveAction(false);
    },
    async discountLineAction() {
      await this.setHeader();
      if (!this.orderFormProvider.showDiscountColumn) {
        for (let i = 0; i < this.receiptFormProviderDetails.length; i++) {
          const receiptFormProviderDetail = this.receiptFormProviderDetails[i];
          if (receiptFormProviderDetail.discount > 0) {
            receiptFormProviderDetail.discount = 0;
            let total = this.calculateTotalreceiptFormProviderLine(
              receiptFormProviderDetail
            );
            this.updateReceiptFormProviderDetail({
              receiptFormProviderDetail: {
                ...receiptFormProviderDetail,
                total: total,
              },
            });
          }
        }
      }
    },
    checkMandatoryInformation,
    executeMultipleAsyncFunctions,
    customOpen(e) {
      if (this.productListIncomplete) this.$refs.tooltip.open(e.target);
    },
    customClose() {
      if (this.productListIncomplete) this.$refs.tooltip.close();
    },
    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeReceiptFormProviderTabActive("receipt-form-provider-pdf");
      });
    },
    async finalizedDocument(action, exit) {
      await this.checkMandatoryInformation()
        .then(async () => {
          if (action == 1) {
            await this.updateReceiptForm({
              receiptFormProvider: { ...this.receiptFormProvider, status: 2 },
              loading: true,
            });
            if (
              this.receiptFormProviderTabActive == "receipt-form-provider-pdf"
            ) {
              this.$emit("changePreviewKey");
            } else {
              await this.changeReceiptFormProviderTabActive(
                "receipt-form-provider-pdf"
              );
            }
          } else if (action == 2) {
            let list = this.receiptFormProviderDetails.filter(
              (el) =>
                (el.productId == null || el.productId == 0) &&
                (el.type == 7 || el.type == 8 || el.type == 9 || el.type == 10)
            );
            if (list.length > 0) {
              this.$bvModal
                .msgBoxConfirm(
                  "Attention, vous avez des lignes de type fourniture, main d'oeuvre ou matériel sans produit associé. Ils seront automatiquement créés. Votre bon de réception ne sera plus modifiable et les quantités réceptionnées sur des points de stockage seront enregistrées dans votre stock. Etes-vous sûr de vouloir continuer ?",
                  {
                    title: "Valider la réception",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Oui",
                    cancelTitle: "Annuler",
                    cancelVariant: "outline-primary",
                    hideHeaderClose: true,
                    centered: true,
                  }
                )
                .then(async (value) => {
                  if (value) {
                    this.$store.commit("SET_IS_VALIDATING_RECEIPT_FORM", true);
                    for (let i = 0; i < list.length; i++) {
                      const el = list[i];
                      let product = ProductDefaultProperties({
                        reference: el.reference,
                        priceHT: 0,
                        productType:
                          el.type == 7
                            ? 0
                            : el.type == 8
                            ? 5
                            : el.type == 9
                            ? 2
                            : 6,
                        description: el.description,
                        unAvailableForSale: true,
                      });
                      if (el.productId == null || el.productId == 0) {
                        await this.createProduct({
                          product: product,
                          reload: false,
                        }).then(async (productResponse) => {
                          el.productId = productResponse.data.id;
                          let productProvider =
                            ProductProviderDefaultProperties({
                              productId: productResponse.data.id,
                              companyId:
                                this.receiptFormProvider.companyProviderId,
                              reference: el.reference,
                              netPrice: el.unitPriceHt,
                              productType:
                                el.type == 7
                                  ? 0
                                  : el.type == 8
                                  ? 5
                                  : el.type == 9
                                  ? 2
                                  : 6,
                              description: el.description,
                              unitId: el.unitId,
                            });

                          if (
                            el.productProviderId == null ||
                            el.productProviderId == 0
                          ) {
                            await this.createProductProvider({
                              productProvider: productProvider,
                              reload: false,
                            }).then(async (productProviderResponse) => {
                              el.productProviderId =
                                productProviderResponse.data.data.id;
                              await this.updateReceiptFormProviderDetail({
                                receiptFormDetail: el,
                              });
                            });
                          } else {
                            await this.updateProductProvider({
                              productProvider: {
                                ...productProvider,
                                id: el.productProviderId,
                              },
                              reload: false,
                            }).then(async (productProviderResponse) => {
                              el.productProviderId =
                                productProviderResponse.data.data.id;
                              await this.updateReceiptFormProviderDetail({
                                receiptFormDetail: el,
                              });
                            });
                          }
                        });
                      }
                    }

                    await this.validatedReceiptFormProvider(exit);
                    this.$store.commit("SET_IS_VALIDATING_RECEIPT_FORM", false);
                  }
                });
            } else {
              this.$bvModal
                .msgBoxConfirm(
                  "Attention, en validant votre bon de réception, celui-ci ne sera plus modifiable. Les quantités réceptionnés sur des points de livraison seront enregistrées dans votre stock.",
                  {
                    title: "Valider la réception",
                    size: "sm",
                    okVariant: "primary",
                    okTitle: "Oui, confirmer",
                    cancelTitle: "Annuler",
                    cancelVariant: "outline-primary",
                    hideHeaderClose: true,
                    centered: true,
                  }
                )
                .then(async (value) => {
                  if (value) {
                    await this.validatedReceiptFormProvider(exit);
                  }
                });
            }
          }
        })
        .catch(async (err) => {
          await this.$swal({
            title: err,
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "Corriger",
            customClass: {
              confirmButton: "btn btn-outline-danger",
            },
            buttonsStyling: false,
          });
        });
    },

    async verifProductsOnReceiptFormProvider() {
      this.receiptFormProviderDetails.forEach((line) => {
        if (
          line.type == 7 ||
          line.type == 8 ||
          line.type == 9 ||
          line.type == 10
        ) {
          if (line.productId == null || line.productId == 0) {
            line.error = true;
          }
          if (line.reference == null || line.reference == "") {
            line.error = true;
          }
        }
      });

      // let lineError = this.receiptFormProviderDetails.filter(line =>
      //           (line.productId == null || line.productId == 0) &&
      //           (line.type == 7 || line.type == 8 || line.type == 9 || line.type == 10)
      //       );
      // this.$bvModal
      //   .msgBoxConfirm(
      //     "Attention, en validant votre bon de réception, celui-ci ne sera plus modifiable. Les quantités réceptionnés sur des points de livraison seront enregistrées dans votre stock.",
      //     {
      //       title: "Valider la réception",
      //       size: "sm",
      //       okVariant: "primary",
      //       okTitle: "Oui, confirmer",
      //       cancelTitle: "Annuler",
      //       cancelVariant: "outline-primary",
      //       hideHeaderClose: true,
      //       centered: true,
      //     }
      //   )
      //   .then(async (value) => {
      //     if (value) {
      //     }
      //   });
    },
    async validatedReceiptFormProvider(exit) {
      this.$store.commit("SET_IS_VALIDATING_RECEIPT_FORM", true);
      await this.updateReceiptForm({
        receiptFormProvider: { ...this.receiptFormProvider, status: 3 },
        loading: true,
      });

      // Si la tab affiché correspond déjà à l'aperçu alor son rafrachit le composant pour que l'aperçu se regénère
      if (this.receiptFormProviderTabActive == "receipt-form-provider-pdf") {
        this.$emit("changePreviewKey");
      } else {
        await this.changeReceiptFormProviderTabActive(
          "receipt-form-provider-pdf"
        );
      }

      this.$store.commit("SET_IS_VALIDATING_RECEIPT_FORM", false);
    },
    async checkStatusBeforeSave(exit) {
      this.verifProductsOnReceiptFormProvider();
      await this.updateReceiptForm({
        receiptFormProvider: {
          ...this.receiptFormProvider,
          status:
            this.receiptFormProvider.status == 0
              ? 1
              : this.receiptFormProvider.status,
        },
        loading: true,
      });
      if (exit) {
        this.$tabs.close({ to: "/receipt-form-providers" });
      }
    },
    checkIfPreviewIsPossible() {
      if (
        this.receiptFormProvider.companyProviderId &&
        this.receiptFormProvider.storageLocationId
      ) {
        return true;
      } else {
        return false;
      }
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute(
          "download",
          this.receiptFormProvider.documentReference
        );
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    cancel() {
      if (
        this.receiptFormProvider.status !== 0 &&
        this.receiptFormProvider.status !== 1
      ) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `Le bon de réception n'ayant aucun statut, vous êtes sur le point de le supprimer. Attention, cette action est irreversible.`,
            {
              title: "Êtes-vous sûr de vouloir supprimer votre document ?",
              size: "sm",
              okVariant: "danger",
              okTitle: "Supprimer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              if (
                this.receiptFormProvider.status == 0 ||
                this.receiptFormProvider.status == 1
              ) {
                this.deleteReceiptFormProviders({
                  receiptFormProviderIds: [this.receiptFormProvider.id],
                }).then(() => {
                  this.closeTab();
                });
              } else {
                this.closeTab();
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
</style>
