<template lang="pug">
div(class="invoiceHeader")
  b-row
    b-col.col-sm-6(class="invoiceHeaderLeft")
      img.logo.mb-2(v-if="receiptFormProvider.institutionLogo && !receiptFormOption.hideInstitutionLogo" :src='receiptFormProvider.institutionLogo')
      ul 
        li.mb-2
          h2(:style="'color:'+receiptFormProvider.institutionColor+' !important'" v-if="!receiptFormOption.hideInstitutionName") {{receiptFormProvider.institutionName}}
          div(v-if="!receiptFormOption.hideInstitutionAddress")
            div {{receiptFormProvider.institutionAddress}}
            div(v-if="receiptFormProvider.institutionAddressComplement") {{receiptFormProvider.institutionAddressComplement}}
            div(v-if="receiptFormProvider.institutionZipCode || receiptFormProvider.institutionCity") {{receiptFormProvider.institutionZipCode}} {{receiptFormProvider.institutionCity}}
        div(v-if="!receiptFormOption.hideUser")
          vs-divider.pl-0.mt-2.mb-50.text-primary(position="left") Traité par
          li
            UserInfoReceiptFormProvider(:preview="true" placeholder="Sélectionner un utilisateur" )
        div(v-if="!receiptFormOption.hideCompanyCustomer")
          vs-divider.pl-0.mt-1.mb-50.text-primary(position="left" style="font-size: 90%;" v-if="receiptFormProvider.companyCustomerId && (!receiptFormOption.hideCompanyCustomer || !receiptFormOption.hideAffair)") Associé à un client / une affaire : 
          li(v-if="receiptFormProvider.companyCustomerId && !receiptFormOption.hideCompanyCustomer")
            SelectCompany(:preview="true" placeholder="Sélectionner un client" :options='companiesList.filter(elem => elem.companyType.id == 5)')
        li(v-if="receiptFormProvider.affairId && !receiptFormOption.hideAffair")
          SelectAffair(:preview="true" placeholder="Sélectionner une affaire" :options='affairsList')
    b-col.col-sm-6(class="invoiceHeaderRight")
      ul(style="height:fit-content")
        li 
          h3(:style="'color:'+receiptFormProvider.institutionColor+' !important'") Bon de réception n° {{ receiptFormProvider.documentReference ?  receiptFormProvider.documentReference : 'en attente' }} 
            span(style='font-weight:300')
        li
          SelectDate(keyValue="documentDate" documentDetailKey="documentDate" :preview="true" text="En date du ")
        li
          br(style="height:10px")
        div(v-if="!receiptFormOption.hideCompanyProvider")
          vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Fournisseur :
          div
            SelectProvider(:options='companiesList.filter(elem => elem.companyType.id == 4)' :preview="true")
        div(v-if="!receiptFormOption.hideStorageLocation")
          vs-divider.pl-0.mt-0.mb-50.text-primary(position="left") Point de stockage :
          div
            SelectStorageLocation(:options='storageLocationsList' :preview="true")
  b-row
    b-col.mb-0(cols="12" class="align-items-center")
      span.text-primary.font-weight-bold.no-printting Rappel des articles
  v-style
    |table:after { background-color: {{receiptFormProvider.institutionColor}} !important;}    
</template>
<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";

import SelectCompany from "@/components/purchase/receipt-form-provider/builder/SelectCompany";
import SelectProvider from "@/components/purchase/receipt-form-provider/builder/SelectProvider";
import SelectStorageLocation from "@/components/purchase/receipt-form-provider/builder/SelectStorageLocation";
import SelectCustomer from "@/components/purchase/receipt-form-provider/builder/SelectCustomer";
import SelectAffair from "@/components/purchase/receipt-form-provider/builder/SelectAffair";
import SelectDate from "@/components/purchase/receipt-form-provider/builder/SelectDate";
import UserInfoReceiptFormProvider from "@/components/purchase/receipt-form-provider/builder/UserInfoReceiptFormProvider";
export default {
  data() {
    return {
      collaboratorInfo: null,
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "institution",
      "companiesList",
      "affairsList",
      "storageLocationsList",
      "receiptFormOption",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectCompany,
    SelectCustomer,
    SelectAffair,
    SelectDate,
    UserInfoReceiptFormProvider,
    SelectProvider,
    SelectStorageLocation,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
