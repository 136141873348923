<template lang="pug">
    .row
        .col-md-6.toolbar.w-100.d-flex
            b-button.mr-1.button-tools(@click="newLine({type:7, unit : 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine7")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Fourniture
            
            b-button.mr-1.button-tools(@click="newLine({type: 8, unit: 2, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine8")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Main d'oeuvre

            b-button.mr-1.button-tools(@click="newLine({type : 9, unit: 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine9")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Matériel
            b-button.mr-1.button-tools(@click="newLine({type : 10, unit: 1, indexArray: lastDocumentLine, review: false})" variant="outline-primary") 
                div(v-if="isLoadingLine10")
                    b-spinner.mr-1(small)
                    | Chargement...
                div(v-else)
                    feather-icon(icon="PlusIcon")
                    span Autre
        .col-md-6.toolbar.w-100.d-flex.align-right.justify-content-end
          b-dropdown.mr-1(v-if="includeSection" variant="outline-primary" text="Section ...") 
              b-dropdown-item(@click="newLine({type: 1, indexArray: lastDocumentLine, review: false})") Section
              b-dropdown-item(@click="newLine({type: 2, indexArray: lastDocumentLine, review: false})") Sous-Section

          b-button.mr-1.button-tools(v-else @click="newLine({type:1, indexArray: lastDocumentLine, review: false})" variant="outline-dark") 
              div(v-if="isLoadingLine1")
                  b-spinner.mr-1(small)
                  | Chargement...
              div(v-else)
                  span Section

          //- b-button.mr-1.button-tools(@click="addSubTotalLine({indexArray: lastDocumentLine})" variant="outline-dark") 
          //-     div(v-if="isLoadingLine5")
          //-         b-spinner.mr-1(small)
          //-         | Chargement...
          //-     div(v-else)
          //-         span Sous-total

          b-button.mr-1.button-tools(@click="newLine({type:6, indexArray: lastDocumentLine, review: false})" variant="outline-dark") 
              div(v-if="isLoadingLine6")
                  b-spinner.mr-1(small)
                  | Chargement...
              div(v-else)
                  span Texte

          b-dropdown(variant="outline-primary" text="Saut ...") 
              b-dropdown-item(@click="newLine({type: 3, indexArray: lastDocumentLine, review: false})") Saut de ligne
              b-dropdown-item(@click="newLine({type: 4, indexArray: lastDocumentLine, review: false})") Saut de page
        
</template>
<script>
import { BButton, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { calculateSubTotalLine } from "@/types/api-orisis/library/ReceiptFormProviderOperations";

export default {
  data() {
    return {
      isLoadingLine1: false,
      isLoadingLine5: false,
      isLoadingLine6: false,
      isLoadingLine7: false,
      isLoadingLine8: false,
      isLoadingLine9: false,
      isLoadingLine10: false,
    };
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["receiptFormProvider"]),
    lastDocumentLine() {
      return this.$store.getters.getReceiptFormProviderDetails.length - 1;
    },
    includeSection() {
      return (
        this.$store.getters.getReceiptFormProviderDetails.filter(
          (el) => el.type == 1
        ).length > 0
      );
    },
  },
  methods: {
    calculateSubTotalLine,
    ...mapActions(["addLineReceiptFormProvider"]),
    async addSubTotalLine(line) {
      this.isLoadingLine5 = true;
      await this.calculateSubTotalLine(
        {
          ...line,
          type: 5,
          review: false,
          receiptFormId: this.receiptFormProvider.id,
          referencielTvaId: 5,
          quantity: 1,
          description: "Sous-total",
        },
        true
      );
      this.isLoadingLine5 = false;
    },
    newLine(line) {
      if (line.type == 1) this.isLoadingLine1 = true;
      if (line.type == 5) this.isLoadingLine5 = true;
      if (line.type == 6) this.isLoadingLine6 = true;
      if (line.type == 7) this.isLoadingLine7 = true;
      if (line.type == 8) this.isLoadingLine8 = true;
      if (line.type == 9) this.isLoadingLine9 = true;
      if (line.type == 10) this.isLoadingLine10 = true;
      this.addLineReceiptFormProvider({
        payload: {
          ...line,
          receiptFormProviderId: this.receiptFormProvider.id,
        },
      })
        .then((res) => {
          if (line.type == 1) this.isLoadingLine1 = false;
          if (line.type == 5) this.isLoadingLine5 = false;
          if (line.type == 6) this.isLoadingLine6 = false;
          if (line.type == 7) this.isLoadingLine7 = false;
          if (line.type == 8) this.isLoadingLine8 = false;
          if (line.type == 9) this.isLoadingLine9 = false;
          if (line.type == 10) this.isLoadingLine10 = false;
        })
        .catch((err) => {
          if (line.type == 1) this.isLoadingLine1 = false;
          if (line.type == 5) this.isLoadingLine5 = false;
          if (line.type == 6) this.isLoadingLine6 = false;
          if (line.type == 7) this.isLoadingLine7 = false;
          if (line.type == 8) this.isLoadingLine8 = false;
          if (line.type == 9) this.isLoadingLine9 = false;
          if (line.type == 10) this.isLoadingLine10 = false;
        });
    },
  },
  mounted() {
    this.isLoadingLine1 = false;
    this.isLoadingLine5 = false;
    this.isLoadingLine6 = false;
    this.isLoadingLine7 = false;
    this.isLoadingLine8 = false;
    this.isLoadingLine9 = false;
    this.isLoadingLine10 = false;
  },
};
</script>
