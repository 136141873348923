<template lang="pug">  
  quill-editor(@focus="focusFunction(true)" @blur="blurFunction(false)" :disabled="line.isDisabled"  :class="[editFocus && mouseOn && canEdit ? 'edit' : 'no-edit', editFocus? 'focus' : '', line.isDisabled ? 'disabled' : '']" v-model="line['description']" :options="editorOption")
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      editorOption: {
        placeholder: "Modifier le texte ...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  props: {
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    editFocus: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(["updateReceiptFormProviderDetail"]),
    blurFunction(res) {
      this.updateReceiptFormProviderDetail({ receiptFormDetail: this.line });
    },
    focusFunction(res) {
      this.$emit("emitEditFocus", res);
    },
  },
  components: {
    quillEditor,
  },
};
</script>
