<template lang="pug">
  div
    div.content-span-document-detail(v-if="!receiptFormProviderHeader[dataKey].editable")
      span(v-model="line[dataKey]") 
      span {{receiptFormProviderHeader[dataKey].prefix }} 
      span {{receiptFormProviderHeader[dataKey].numberToFixed ? (line[dataKey]).toFixed(receiptFormProviderHeader[dataKey].numberToFixed) : line[dataKey]}}
      span {{receiptFormProviderHeader[dataKey].unit}}
    input.w-100(:disabled="line.isDisabled" @focus="emitEditFocus(true)" @blur="blurFunction(false)" v-else :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled ? 'disabled' : '']" :style="line.error && line.error == true ? 'border:1px solid red !important' : ''" v-model="line[dataKey]")
  
</template>
<script>
//  @keyup.enter="selectProduct()"
import { mapActions, mapGetters } from "vuex";
import { productMergeByTypeLine } from "@/types/api-orisis/library/DetailReceiptFormProviderOperations.ts";
import { calculateSubTotalLineBySection } from "@/types/api-orisis/library/ReceiptFormProviderOperations";

import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";

export default {
  data() {
    return {
      calculateSubTotalLineBySection,
    };
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    receiptFormProviderHeader: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters([
      "productProviderSortedByProductType",
      "getReceiptFormProviderDetails",
      "receiptFormProvider",
      "setModalReceiptForm",
    ]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    productMergeByTypeLine,
    ...mapActions([
      "updateReceiptFormProviderDetail",
      "updateReceiptForm",
      "getCollaboratorById",
      "getCompanyById",
    ]),
    getDefaultAddressCompany(company) {
      return new Promise((resolve, reject) => {
        company.addresses.find((adress) => {
          if (adress.isDefault) {
            resolve(adress);
          }
        });
      });
    },
    async setSelectProvider(company) {
      if (company.id == null) {
        this.collaboratorInfo = null;
        this.receiptFormProvider = {
          ...this.receiptFormProvider,
          companyProviderId: company.id,
          companyProviderName: company.name,
          companyProviderAddress: company.address,
          companyProviderAddressComplement: company.addressComplement,
          companyProviderCity: company.city,
          companyProviderZipCode: company.zipCode,
          companyProviderCountry: company.country,
          companyProviderSiret: company.siret,
        };
        // this.saveAction();
      } else {
        await this.getCompanyById({ companyId: company.id }).then(
          async (company) => {
            this.collaboratorInfo = company.collaboratorId
              ? await this.getCollaboratorById({
                  collaboratorId: company.collaboratorId,
                })
              : null;
            if (company.addresses && company.addresses.length > 0) {
              await this.getDefaultAddressCompany(company).then((res) => {
                this.receiptFormProvider = {
                  ...this.receiptFormProvider,
                  companyProviderId: company.id,
                  companyProviderName: company.name,
                  companyProviderAddress: res.address,
                  companyProviderAddressComplement: res.addressComplement,
                  companyProviderCity: res.city,
                  companyProviderZipCode: res.zipCode,
                  companyProviderCountry: res.country,
                  companyProviderSiret: res.siret,
                };
              });
            } else {
              this.receiptFormProvider = {
                ...this.receiptFormProvider,
                companyProviderId: company.id,
                companyProviderName: company.name,
                companyProviderSiret: company.siret,
                companyProviderAddress: null,
                companyProviderAddressComplement: null,
                companyProviderCity: null,
                companyProviderZipCode: null,
                companyProviderCountry: null,
              };
            }
          }
        );
        this.saveAction();
      }
    },
    saveAction() {
      this.updateReceiptForm({
        receiptFormProvider: this.receiptFormProvider,
        loading: false,
      });
    },
    async affectValue(val) {
      //Si c'est le premier produit des détails, on affecte le fournisseur du produit au bon de réception
      if (
        !this.getReceiptFormProviderDetails.find(
          (elem) => elem.productProviderId != null
        )
      ) {
        this.setSelectProvider(val.company);
      }
      this.line["description"] = val.description ? val.description : val.label;
      this.line["productProviderId"] = val.id;
      this.line["reference"] = val.reference ? val.reference : null;
      this.line["unitId"] = val.unit ? val.unit.id : this.line["unitId"];

      switch (val.productType) {
        case 0:
          this.line["type"] = 7;
          break;
        case 2:
          this.line["type"] = 9;
          break;
        case 4:
          this.line["type"] = 6;
          break;
        case 5:
          this.line["type"] = 8;
          break;
      }
      await this.updateReceiptFormProviderDetail({
        receiptFormDetail: this.line,
      });
      await this.calculateSubTotalLineBySection(this.line);
    },
    productProvidersListByTypeLine() {
      return this.productMergeByTypeLine(
        this.line,
        this.productProviderSortedByProductType,
        this.receiptFormProvider.companyProviderId
      );
      // }
    },
    async selectProduct() {
      this.line.isCreatingProduct = false;
      if (
        this.dataKey == "reference" &&
        this.line[this.dataKey] !== null &&
        this.line[this.dataKey] !== ""
      ) {
        this.line["error"] = false;
        let item = null;
        for (let propriete in this.productProvidersListByTypeLine()) {
          if (this.productProvidersListByTypeLine().hasOwnProperty(propriete)) {
            const tableau = this.productProvidersListByTypeLine()[propriete];
            const resultatTrouve = tableau.find(
              (element) => element.reference == this.line[this.dataKey]
            );
            if (resultatTrouve) {
              item = resultatTrouve;
              break;
            }
          }
        }
        if (item) {
          this.affectValue(item);
        }
      } else if (this.dataKey == "reference") {
        this.line["error"] = true;
      }
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    blurFunction(res) {
      this.selectProduct();
      this.emitEditFocus(res);
      this.updateReceiptFormProviderDetail({ receiptFormDetail: this.line });
    },
  },
};
</script>
