<template lang="pug">
  vs-divider(color="dark" v-if="!preview") Saut de page
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    preview: {
      default: false,
    },
  },
};
</script>
