<template lang="pug">
div(v-if="(dataKey == 'stock' && !line['productId'])")
  div.content-span-document-detail
    span
div(v-else-if="!receiptFormProviderHeader[dataKey].editable || (dataKey == 'orderedQuantity' && receiptFormProvider.orderFormId)")
  div.content-span-document-detail
    span(v-model="line[dataKey]") 
    span {{receiptFormProviderHeader[dataKey].prefix }} 
    span {{ formatNumberToString(line[dataKey]) }}
    span {{receiptFormProviderHeader[dataKey].unit}}
input.w-100(v-else :disabled="line.isDisabled && dataKey != 'receivedQuantity'" @focus="emitEditFocus(true)" @blur="blurFunction(false)" type="number" min='0'  :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled && dataKey != 'receivedQuantity' ? 'disabled':'']" v-model="line[dataKey]") 
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  formatNumber,
  formatNumberToString,
} from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  computed: {
    ...mapGetters(["receiptFormProvider"]),
  },

  methods: {
    ...mapActions(["updateReceiptFormProviderDetail"]),
    formatNumber,
    formatNumberToString,
    change(res) {
      this.line[this.dataKey] = formatNumber(this.line[this.dataKey]);
    },
    async blurFunction(res) {
      this.$emit("emitEditFocus", res);
      await this.change(null);
      await this.updateReceiptFormProviderDetail({
        receiptFormDetail: this.line,
      });
    },
    emitEditFocus(res) {
      this.edit = res;
      this.$emit("emitEditFocus", res);
    },
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    receiptFormProviderHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
  },
};
</script>
