import Vue from 'vue'
import store from '@/store'
import { mapGetters, mapActions } from "vuex"

export default {
    name: 'IFrame',
    data() {
        return {
            iApp: null,
        }
    },
    computed: {
      ...mapGetters([
        "receiptFormProvider",
        "receiptFormProviderPDF",
        "institutionSelected",
        "workspaceSelected"
      ])
    },
    render(h) {
        return h('iframe', {
            on: {
                load: this.renderChildren
            }
        })
    },
    methods: {
        ...mapActions(["generateReceiptFormProviderPDF"]),
        async renderChildren() {
            const children = this.$slots.default
            const body = this.$el.contentDocument.body
            this.$el.contentDocument.head.innerHTML = '<meta charset="utf-8"><link href="https://v2.orisis.fr/css/pdf-generator/bootstrap-grid.css" rel="stylesheet"><link href="https://v2.orisis.fr/css/pdf-generator/main.css" rel="stylesheet"><link href="https://v2.orisis.fr/css/pdf-generator/print.css" rel="stylesheet" media="print">'

            const el = document.createElement('div') // we will mount or nested app to this element
            body.appendChild(el)

            const iApp = new Vue({
                name: 'iApp',
                store,
                data() {
                    return {
                        children: Object.freeze(children)
                    }
                },
                render(h) {
                    return h('div', this.children)
                }
            })

            iApp.$mount(el)

            let htmlPdf = encodeURI(body.innerHTML.replaceAll('<!---->', ''))

            let env=`${process.env.VUE_APP_ENV}`
            if(this.receiptFormProvider.status < 3){
                let path = this.workspaceSelected.uniqueIdentifier+"/"+this.institutionSelected.uniqueIdentifier+"/Achat/Temp/"+this.receiptFormProvider.uniqueIdentifier+".pdf"
                var data = JSON.stringify({
                    "env": env,
                    "path": path,
                    "html": htmlPdf
                });
                await this.generateReceiptFormProviderPDF(data)
            }else{
                let path = this.workspaceSelected.uniqueIdentifier+"/"+this.institutionSelected.uniqueIdentifier+"/Achat/Bons_de_réception/"+this.receiptFormProvider.documentReference+".pdf"
                var data = JSON.stringify({
                    "env": env,
                    "path": path,
                    "html": htmlPdf
                });
                await this.generateReceiptFormProviderPDF(data)
            }
        },
    }
}