<template lang="pug">
    div
      .document-head.bg-primary
          .product-line.builder
              div(v-for="(key, i) in Object.keys(receiptFormProviderHeader)" :key="i" v-if="receiptFormProviderHeader[key].display" :class="receiptFormProviderHeader[key].display ? receiptFormProviderHeader[key].class : ''" :style="receiptFormProviderHeader[key].style") {{receiptFormProviderHeader[key].text}}  
  </template>
<script>
export default {
  data() {
    return {};
  },

  //options:
  computed: {
    receiptFormProviderHeader: {
      get() {
        return this.$store.getters.getReceiptFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setReceiptFormProviderHeader", val);
      },
    },
  },
};
</script>