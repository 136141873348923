<template lang="pug">
    .builder.pb-4
      .builder-area
        ReceiptFormProviderHeader()
        ReceiptFormProviderTable()
        ReceiptFormProviderBTools()
        ReceiptFormProviderFooter()
  </template>

<script>
import ReceiptFormProviderHeader from "@/components/purchase/receipt-form-provider/builder/ReceiptFormProviderHeader.vue";
import ReceiptFormProviderTable from "@/components/purchase/receipt-form-provider/builder/ReceiptFormProviderTable.vue";
import ReceiptFormProviderBTools from "@/components/purchase/receipt-form-provider/builder/ReceiptFormProviderBTools.vue";
import ReceiptFormProviderFooter from "@/components/purchase/receipt-form-provider/builder/ReceiptFormProviderFooter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ReceiptFormProviderHeader,
    ReceiptFormProviderTable,
    ReceiptFormProviderBTools,
    ReceiptFormProviderFooter,
  },
  computed: {
    ...mapGetters(["institution", "institutionSettingsActive"]),
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
    receiptFormProviderDetails: {
      get() {
        return this.$store.getters["getReceiptFormProviderDetails"];
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM_DETAILS", value);
      },
    },
    mandatoryInformationMissingNumber() {
      if (
        this.receiptFormProvider.institutionLegalForm == 0 ||
        this.receiptFormProvider.institutionLegalForm == 1
      ) {
        var mandatoryInformation = [
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else if (
        this.receiptFormProvider.institutionLegalForm == 2 ||
        this.receiptFormProvider.institutionLegalForm == 3
      ) {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      } else {
        var mandatoryInformation = [
          "institutionSiret",
          "institutionRcsCity",
          "institutionCapital",
          "institutionApeCode",
          "institutionTvaNumber",
          "institutionInsuranceName",
          "institutionGuaranteeType",
          "institutionInsuranceCoverage",
          "institutionInsuranceAddress",
        ];
      }
      let missing = 0;
      for (let i = 0; i < mandatoryInformation.length; i++) {
        const mandatory = mandatoryInformation[i];
        if (
          this.receiptFormProvider[mandatory] === null ||
          this.receiptFormProvider[mandatory] === ""
        ) {
          missing++;
        }
      }
      return missing;
    },
    institutionInformationsNotUpToDate() {
      return (
        this.institution.name != this.receiptFormProvider.institutionName ||
        this.institution.legalForm !=
          this.receiptFormProvider.institutionLegalForm ||
        this.institution.address !=
          this.receiptFormProvider.institutionAddress ||
        this.institution.addressComplement !=
          this.receiptFormProvider.institutionAddressComplement ||
        this.institution.zipCode !=
          this.receiptFormProvider.institutionZipCode ||
        this.institution.city != this.receiptFormProvider.institutionCity ||
        this.institution.country !=
          this.receiptFormProvider.institutionCountry ||
        this.institution.phoneNumber !=
          this.receiptFormProvider.institutionPhoneNumber ||
        this.institution.secondaryPhoneNumber !=
          this.receiptFormProvider.institutionSecondaryPhoneNumber ||
        this.institution.email != this.receiptFormProvider.institutionEmail ||
        this.institution.webSite !=
          this.receiptFormProvider.institutionWebSite ||
        this.institution.registrationNumber !=
          this.receiptFormProvider.institutionRegistrationNumber ||
        this.institution.isSubjectTVA !=
          this.receiptFormProvider.institutionIsSubjectTVA ||
        this.institution.tvaNumber !=
          this.receiptFormProvider.institutionTvaNumber ||
        this.institution.siret != this.receiptFormProvider.institutionSiret ||
        this.institution.rcsCity !=
          this.receiptFormProvider.institutionRcsCity ||
        this.institution.capital !=
          this.receiptFormProvider.institutionCapital ||
        this.institution.apeCode !=
          this.receiptFormProvider.institutionApeCode ||
        this.institution.guaranteeType !=
          this.receiptFormProvider.institutionGuaranteeType ||
        this.institution.insuranceName !=
          this.receiptFormProvider.institutionInsuranceName ||
        this.institution.insuranceCoverage !=
          this.receiptFormProvider.institutionInsuranceCoverage ||
        this.institution.insuranceAddress !=
          this.receiptFormProvider.institutionInsuranceAddress ||
        this.institution.insuranceAddressComplement !=
          this.receiptFormProvider.institutionInsuranceAddressComplement ||
        this.institution.insuranceCity !=
          this.receiptFormProvider.institutionInsuranceCity ||
        this.institution.insuranceZipCode !=
          this.receiptFormProvider.institutionInsuranceZipCode ||
        this.institution.insuranceCountry !=
          this.receiptFormProvider.institutionInsuranceCountry ||
        this.institution.color != this.receiptFormProvider.institutionColor ||
        this.institution.secondaryColor !=
          this.receiptFormProvider.institutionSecondaryColor
      );
    },
  },
  methods: {
    ...mapActions(["updateReceiptForm"]),
    redirectToInstitution() {
      this.$router.push({
        name: "edit-institution",
        params: {
          id: this.receiptFormProvider.institutionId,
          title: "Edit : " + this.receiptFormProvider.institutionName,
          tips:
            "Editer la société : " + this.receiptFormProvider.institutionName,
          routeOrigine: "institutions",
        },
      });
    },
    saveAction(bool) {
      this.updateReceiptForm({
        receiptFormProvider: this.receiptFormProvider,
        loading: bool,
      });
    },
    updateDocumentInstitutionInformations() {
      this.receiptFormProvider.institutionName = this.institution.name;
      this.receiptFormProvider.institutionLegalForm =
        this.institution.legalForm;
      this.receiptFormProvider.institutionAddress = this.institution.address;
      this.receiptFormProvider.institutionAddressComplement =
        this.institution.addressComplement;
      this.receiptFormProvider.institutionZipCode = this.institution.zipCode;
      this.receiptFormProvider.institutionCity = this.institution.city;
      this.receiptFormProvider.institutionCountry = this.institution.country;
      this.receiptFormProvider.institutionPhoneNumber =
        this.institution.phoneNumber;
      this.receiptFormProvider.institutionSecondaryPhoneNumber =
        this.institution.secondaryPhoneNumber;
      this.receiptFormProvider.institutionEmail = this.institution.email;
      this.receiptFormProvider.institutionWebSite = this.institution.webSite;
      this.receiptFormProvider.institutionRegistrationNumber =
        this.institution.registrationNumber;
      this.receiptFormProvider.institutionIsSubjectTVA =
        this.institution.isSubjectTVA;
      this.receiptFormProvider.institutionTvaNumber =
        this.institution.tvaNumber;
      this.receiptFormProvider.institutionSiret = this.institution.siret;
      this.receiptFormProvider.institutionRcsCity = this.institution.rcsCity;
      this.receiptFormProvider.institutionCapital = this.institution.capital;
      this.receiptFormProvider.institutionApeCode = this.institution.apeCode;
      this.receiptFormProvider.institutionGuaranteeType =
        this.institution.guaranteeType;
      this.receiptFormProvider.institutionInsuranceName =
        this.institution.insuranceName;
      this.receiptFormProvider.institutionInsuranceCoverage =
        this.institution.insuranceCoverage;
      this.receiptFormProvider.institutionInsuranceAddress =
        this.institution.insuranceAddress;
      this.receiptFormProvider.institutionInsuranceAddressComplement =
        this.institution.insuranceAddressComplement;
      this.receiptFormProvider.institutionInsuranceCity =
        this.institution.insuranceCity;
      this.receiptFormProvider.institutionInsuranceZipCode =
        this.institution.insuranceZipCode;
      this.receiptFormProvider.institutionInsuranceCountry =
        this.institution.insuranceCountry;
      this.receiptFormProvider.institutionColor = this.institution.color;
      this.receiptFormProvider.institutionSecondaryColor =
        this.institution.secondaryColor;
      this.saveAction(false);
    },
  },
};
</script>

<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>
