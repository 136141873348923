<template lang="pug">
    .row 
      div.col-md-12
        b-checkbox.mb-2(v-model="document.isFaulty" @change='saveAction') Commande défectueuse
        BottomNotes
        //- SelectAttachments
</template>
<script>
import BottomNotes from "@/components/purchase/receipt-form-provider/builder/BottomNotes.vue";
import SelectAttachments from "@/components/purchase/receipt-form-provider/builder/SelectAttachments.vue";
import { mapActions } from "vuex";

export default {
  computed: {
    document: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateReceiptForm"]),
    saveAction() {
      this.updateReceiptForm({
        receiptFormProvider: this.document,
        loading: false,
      });
    },
  },
  components: {
    SelectAttachments,
    BottomNotes,
  },
};
</script>
