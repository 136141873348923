<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingReceiptFormProvider || isLoadingReceiptFormProviderPDF || isValidatingReceiptFormProvider" class="h-100")
        .text-center.flex-center.h-100
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
            br
            br
            br
            |  {{ isValidatingReceiptFormProvider ? "Enregistrement de la réception de commande en cours, veuillez patienter..." : "Génération du PDF..." }}
  div(style='display:contents' v-show="!isLoadingReceiptFormProvider && !isLoadingReceiptFormProviderPDF && !isValidatingReceiptFormProvider")
    iframe.pdfViewver(:src="receiptFormProviderPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents" v-if="receiptFormProvider.status < 3 || !receiptFormProvider.path || receiptFormProvider.path.includes('null.pdf')")
      ReceiptFormProviderPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:none")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                  ReceiptFormProviderPreviewHeader()
                  ReceiptFormProviderPreviewTable()
                  ReceiptFormProviderPreviewFooter()
                  .watermark(v-if="receiptFormProvider.status == 0 || receiptFormProvider.status == 1")
                    .text PROVISOIRE
                  .watermark(v-else-if="receiptFormProvider.status == 2")
                    .text A VALIDER
                  .watermark(v-else-if="receiptFormProvider.status == 4")
                    .text ANNULÉ
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
</template>
<script>
import { mapGetters } from "vuex";

import ReceiptFormProviderPdfContent from "@/components/purchase/receipt-form-provider/ReceiptFormProviderPdfContent";
import ReceiptFormProviderPreviewHeader from "@/components/purchase/receipt-form-provider/preview/ReceiptFormProviderPreviewHeader.vue";
import ReceiptFormProviderPreviewTable from "@/components/purchase/receipt-form-provider/preview/ReceiptFormProviderPreviewTable.vue";
import ReceiptFormProviderPreviewFooter from "@/components/purchase/receipt-form-provider/preview/ReceiptFormProviderPreviewFooter.vue";

import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  computed: {
    ...mapGetters([
      "receiptFormProvider",
      "receiptFormProviderPDF",
      "isLoadingReceiptFormProvider",
      "isLoadingReceiptFormProviderPDF",
      "isValidatingReceiptFormProvider",
    ]),
    heightFooter() {
      return "100";
    },
  },
  components: {
    ReceiptFormProviderPdfContent,
    ReceiptFormProviderPreviewHeader,
    ReceiptFormProviderPreviewTable,
    ReceiptFormProviderPreviewFooter,

  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      var count = 0;
      if (this.receiptFormProvider.footer) {
        count =
          ((this.receiptFormProvider.footer.match(/\<p/g) || []).length +
            (this.receiptFormProvider.footer.match(/\<br/g) || []).length +
            (this.receiptFormProvider.footer.match(/\<\/br/g) || []).length) *
          12;
      }
      if (count > 50) {
        return count;
      } else {
        return 50;
      }
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>
