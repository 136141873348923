<template lang="pug">
    .d-flex.mb-2
      .w-100(v-if="!preview")
        h4.text-primary.font-weight-bold Justificatifs
        ag-grid-vue.ag-theme-material(style="width: 100%; height: 200px" :enableCellChangeFlash="true" :animateRows="true" :gridOptions="gridOptions" :rowData="rowData" @rowDoubleClicked="onRowDoubleClicked")
      .w-100.mt-2(v-else)
        h4(v-if="document.comments") Notes
        div(v-html="document.comments")
  </template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {
      rowData: [],
      gridOptions: {
        floatingFiltersHeight: 0,
        localeText: agGridLocaleFR,
        columnDefs: [
          { headerName: "Type", field: "type", wrapText: true },
          { headerName: "Nom", field: "name", wrapText: true },
        ],
        // defaultColDef: {
        //   flex: 1,
        //   minWidth: 100,
        //   editable: false,
        // },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 10,
        enableCellChangeFlash: "true",
      },
      edit: false,
      description: null,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
    };
  },
  components: {
    quillEditor,
    AgGridVue,
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateReceiptForm"]),
    saveAction() {
      this.updateReceiptForm({
        receiptFormProvider: this.document,
        loading: false,
      });
    },
    onRowDoubleClicked() {},
  },
};
</script>
