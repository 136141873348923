<template lang="pug">
#invoiceContainer(v-if="this.$route.fullPath.includes(receiptFormProvider.id)")
  .content-loader-center.m-0(v-if='isLoadingReceiptFormProvider && isLoadingReceiptFormProviderDetailDuplication')
    .text-center.flex-center
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      br
      | Chargement du bon de réception...
  div(v-else style="height: calc(100% - 50px) !important")
    ReceiptFormProviderBuilderHeader(:key="id" @changePreviewKey="changePreviewKey")
    ReceiptFormProviderBuilder(v-if="receiptFormProviderTabActive == 'receipt-form-provider-edit'")
    .content-tab-fullpage(v-else-if="receiptFormProviderTabActive == 'receipt-form-provider-pdf'" :key="previewKey")
      ReceiptFormProviderPdf
    .content-tab-fullpage(v-else-if="receiptFormProviderTabActive == 'internal-note'")
      InternalNote
    .content-tab-fullpage(v-else-if="receiptFormProviderTabActive == 'attachments'")
      ged-viewer-fullpage(:parentFolder="receiptFormProvider.folderId" :fromModule="'receiptFormProvider'")
</template>

<script>
import ReceiptFormProviderBuilderHeader from "@/components/purchase/receipt-form-provider/ReceiptFormProviderBuilderHeader.vue";
import ReceiptFormProviderBuilder from "@/components/purchase/receipt-form-provider/ReceiptFormProviderBuilder.vue";
import ReceiptFormProviderPdf from "@/components/purchase/receipt-form-provider/ReceiptFormProviderPdf.vue";
import ReceiptFormProviderPreview from "@/components/purchase/receipt-form-provider/ReceiptFormProviderPreview.vue";
import { mapGetters, mapActions } from "vuex";
import { ReceiptFormProviderDefaultProperties } from "@/types/api-orisis/models/ReceiptFormProviderModels";
import InternalNote from "@/components/purchase/receipt-form-provider/internal-note/InternalNote.vue";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";

export default {
  name: "edit-receipt-form-provider",
  ref: "edit-receipt-form-provider",

  data() {
    return {
      ReceiptFormProviderDefaultProperties,
      previewKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "receipt-form-provider-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    if (this.receiptFormProvider.status == 3) {
      this.changeReceiptFormProviderTabActive("receipt-form-provider-pdf");
    } else {
      this.changeReceiptFormProviderTabActive(this.mode);
    }

    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.receiptFormProviderHeader.unitId.choice = res;
      });
    } else {
      this.receiptFormProviderHeader.unitId.choice = this.unitsList;
    }

    this.getProductProviders({});
    this.getProducts({});
  },
  computed: {
    ...mapGetters([
      "isLoadingReceiptFormProvider",
      "receiptFormProviderTabActive",
      "unitsList",
      "isLoadingReceiptFormProviderDetailDuplication",
    ]),
    receiptFormProviderHeader: {
      get() {
        return this.$store.getters.getReceiptFormProviderHeader;
      },
      set(val) {
        this.$store.commit("setReceiptFormProviderHeader", {
          ...receiptFormProviderHeader,
          val,
        });
      },
    },
    receiptFormProvider: {
      get() {
        return this.$store.getters.receiptFormProvider;
      },
      set(value) {
        return this.$store.commit("SET_RECEIPT_FORM", value);
      },
    },
  },
  methods: {
    ...mapActions([
      "getProductProviders",
      "getProducts",
      "getUnits",
      "changeReceiptFormProviderTabActive",
    ]),
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    ReceiptFormProviderBuilderHeader,
    ReceiptFormProviderBuilder,
    ReceiptFormProviderPdf,
    ReceiptFormProviderPreview,
    InternalNote,
    GedViewerFullpage,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>
