<template lang="pug">
  td
    div(v-if="receiptFormProviderHeader[dataKey].inputType == 1") 
      span {{line[dataKey]}}
    div(v-else-if="receiptFormProviderHeader[dataKey].inputType == 21")
      span(v-html="line['description']") 
</template>
<script>
import { mapGetters } from "vuex";
import TextCol from "@/components/purchase/receipt-form-provider/builder/table/cols/TextCol.vue";
import NumberCol from "@/components/purchase/receipt-form-provider/builder/table/cols/NumberCol.vue";
import QuillEditorCol from "@/components/purchase/receipt-form-provider/builder/table/cols/QuillEditorCol.vue";

export default {
  data() {
    return {};
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    receiptFormProviderHeader: {
      type: Object,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["receiptFormProvider"]),
  },
  methods: {
    emitEditFocus(res) {
      this.$emit("mainEmitFocus", res);
    },
  },
  components: {
    TextCol,
    NumberCol,
    QuillEditorCol,
  },
};
</script>
